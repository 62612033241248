<template>
   <div id="BodyTitle">
      <b-row>
         <b-col cols="auto" class="p-3">
            <h1>{{title}}</h1>
         </b-col>
         <b-col class="on-print" >
            <b-row class="d-flex justify-content-end align-items-center">
             <b-col cols="auto" class="p-3" v-if="exporting">
                   <b-button pill variant="outline-success" disabled>
                      <span>Carregando relatório... </span>
                     <b-spinner variant="success" class="loading" label="Carregando relatório..."></b-spinner>
                  </b-button>
               </b-col>
               <b-col cols="auto" class="p-3 hide" v-if=followUpPedidos>
                  <input id="btnUpload" type="file" @input="handleFileUpload( $event )"/>
               </b-col>
               <b-col cols="auto" class="p-3" v-if=followUpPedidos>
                  <IndexButton text="Upload" style-svg="text-align: center !important" event="upload"
                               route="" v-on:upload="uploadFile" class="print-button">
                  <svg class="svg-icon" viewBox="0 0 20 20">
							<path fill="none" d="M8.416,3.943l1.12-1.12v9.031c0,0.257,0.208,0.464,0.464,0.464c0.256,0,0.464-0.207,0.464-0.464V2.823l1.12,1.12c0.182,0.182,0.476,0.182,0.656,0c0.182-0.181,0.182-0.475,0-0.656l-1.744-1.745c-0.018-0.081-0.048-0.16-0.112-0.224C10.279,1.214,10.137,1.177,10,1.194c-0.137-0.017-0.279,0.02-0.384,0.125C9.551,1.384,9.518,1.465,9.499,1.548L7.76,3.288c-0.182,0.181-0.182,0.475,0,0.656C7.941,4.125,8.234,4.125,8.416,3.943z M15.569,6.286h-2.32v0.928h2.32c0.512,0,0.928,0.416,0.928,0.928v8.817c0,0.513-0.416,0.929-0.928,0.929H4.432c-0.513,0-0.928-0.416-0.928-0.929V8.142c0-0.513,0.416-0.928,0.928-0.928h2.32V6.286h-2.32c-1.025,0-1.856,0.831-1.856,1.856v8.817c0,1.025,0.832,1.856,1.856,1.856h11.138c1.024,0,1.855-0.831,1.855-1.856V8.142C17.425,7.117,16.594,6.286,15.569,6.286z"></path>
                  </svg>
                  </IndexButton>
               </b-col>
               <b-col id="btn-export" cols="auto" class="p-3">
                  <IndexButton v-if=exportExcel text="Excel" style-svg="text-align: center !important" event="excel"
                               route="" v-on:excel="exportToExcel" class="print-button">
                     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-excel"
                          class="svg-inline--fa fa-file-excel fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512">
                        <path fill="currentColor"
                              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
                     </svg>
                  </IndexButton>
               </b-col>
               <b-col id="btn-print" cols="auto" class="p-3">
                  <IndexButton text="Imprimir" style-svg="text-align: center !important" event="window-print" route=""
                               v-on:window-print="windowPrint" class="print-button">
                     <polyline points="6 9 6 2 18 2 18 9"></polyline>
                     <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
                     <rect height="8" width="12" x="6" y="14"></rect>
                  </IndexButton>
               </b-col>
            </b-row>
         </b-col>

      </b-row>
      <hr>
         <b-alert variant="danger" :show="alertError">
            Erro: {{error}}
            <br>
            <div v-for="item in errorInfo" :key="item">
               {{item[0]}}
            </div >
         </b-alert>
   </div>
</template>

<script>
   import IndexButton from "@/components/IndexButton";
   import axios from "@/axios";

   export default {
      name: "BodyTitle",
      components: {
         IndexButton
      },
      props: {
         title: String,
         exportExcel: Boolean,
         followUpPedidos: {type: Boolean, default: false},
         exporting: {type: Boolean, default: false}
      },
      data() {
         return {
            alertSuccess: false,
            alertError: false,
            error: '',
            file: '',
            errorInfo: [],
            route: window.location.pathname.split("/"),
         }
      },
      watch:{
         exporting() {
            if(this.exporting){
               document.getElementById("btn-export").style.display = "none";
               document.getElementById("btn-print").style.display = "none";
            }else{
               document.getElementById("btn-export").style.display = "block";
               document.getElementById("btn-print").style.display = "block";
            }
         },
         file() {
            if(this.file!=''){
               const formData = new FormData();
               formData.append('file', this.file);
               formData.append('hash', this.route[2]);
               const headers = {
                  'Content-Type': 'multipart/form-data'
               };
               axios
                  .post('/gravar-dados/pedidos-status-xls', formData, { headers })
                  .then((res) => {
                     this.alertError = false
                     location.reload();
                  })
                  .catch((e) => {
                     if (e.response.data.error.errors.file != undefined) {
                        this.error = e.response.data.error.errors.file[0]
                        this.alertError = true
                     } else {
                        if (e.response.data.error.message == "The given data was invalid.") {
                           this.error = "O arquivo contém informações inválidas!"
                        }
                        this.errorInfo = e.response.data.error.errors
                        this.alertError = true
                     }
                     document.getElementById("btnUpload").value = "";
                  });
            }
         }
      },
      methods: {
         windowPrint() {
            window.print();
         },
         exportToExcel() {
            this.$emit('exportExcel');
         },
         handleFileUpload(event) {
            this.file = event.target.files[0];
         },
         uploadFile() {
            document.getElementById('btnUpload').click()
         }
      }
   }
</script>

<style scoped>
   h1 {
      font-size: 32px;
      padding-top: .5em;
   }

   hr {
      background-color: #ced4da;
      border: none;
      height: 1px;
      width: 100%;
   }

   .print-button {
      right: 1em;
      top: 1em;
      width: 10em;
   }

   @media print {
      .on-print {
         display: none;
      }
   }

   .info-button{
      margin-right: 20px;
      width: 57px;
      display: inline-block;
      
   }

   .hide{
      display: none;
   }
</style>
