<template>
    <div id="UserTable">
        <UserFilter v-on:filter-input-change="filter = $event" v-on:filter-check-change="mountedTable(1,$event)"></UserFilter>

        <b-table
                :fields="fields"
                :filter="filter"
                :items="userCollection"
                hover
                no-border-collapse
                ref="selectableTable"
                responsive="lg"
                striped
        >
            <template v-slot:cell(edit)="row">
                <span v-on:click="editUser(row)" v-bind:class="editPermission(row) ? 'btn-table-inner-edit' : 'btn-table-inner-disable'">
                    <svg class="bi bi-gear-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                        <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                    </svg>
                </span>
            </template>

            <template v-slot:cell(delete)="row">
                <span v-on:click="deleteUser(row.item.id)" v-bind:class="deletePermission() ? 'btn-table-inner-delete' : 'btn-table-inner-disable'">
                    <svg class="bi bi-gear-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                    </svg>
                </span>
            </template>
        </b-table>
        <p v-if="userCollection.length === 0 && !loading" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Carregando..."></b-spinner>
        </div>

        <DestinatarioEdit :count="countEdit" :id="destinatarioEdit" v-on:refresh-table="refreshTable++" :item="userItem"></DestinatarioEdit>

        <b-pagination
                class="on-print"
                v-show="!loading && userCollection.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="fieldTable"
                @input="mountedTable"
        ></b-pagination>
    </div>
</template>

<script>
    import UserFilter from "@/components/UserFilter";
    import axios from "@/axios";
    import DestinatarioEdit from "@/modal/DestinatarioEdit";

    export default {
        name: "UserTable",
        components: {
            DestinatarioEdit,
            UserFilter
        },
        props: {
            modalId: String,
            refreshTable: Number,
        },
        watch: {
            refreshTable() {
                this.mountedTable(1);
            }
        },
        data: function () {
            return {
                countEdit: 0,
                filter: '',
                loading: false,
                destinatarioEdit: "destinatarioEdit",
                userItem: {},
                userCollection: [],
                fields: [
                    {key: 'nome', sortable: true},
                    {key: 'descricao', sortable: false},
                    {key: 'email', sortable: true},
                    {key: 'edit', label:"Editar" , sortable: false,},
                    {key: 'delete', label:"Apagar" , sortable: false,},
                ],
                currentPage: 1,
                perPage: 3,
                totalRows: 0,
                filters: {
                    page: 1,
                   },
            }
        },
        methods: {
            editPermission(item) {
                return ((localStorage.user_permission < 2) || (localStorage.user_permission < 3 && item.item.tipo !== 'Administrador' && item.item.tipo !== 'Supervisor') || (localStorage.user_name === item.item.nome))
            },
            deletePermission() {
                return localStorage.user_permission < 2
            },
            editUser(item) {
                if (this.editPermission(item)) {
                    this.userItem = item.item;
                    this.$bvModal.show(this.destinatarioEdit);
                    this.countEdit++;
                }
                else alert("Este usuário não possui permissão para realizar essa ação")
            },
            deleteUser(id) {
                if (this.deletePermission()) {
                    let self = this;
                    if (confirm("Deseja apagar este destinatário para sempre?")) {
                        this.access_token = "Bearer " + localStorage.access_token;
                        axios.delete('/email_relatorio/' + id, {
                            headers: {
                                "Authorization": self.access_token
                            }
                        })
                            .then(function () {
                                self.mountedTable(1);
                            })
                            .catch(function (error) {
                                alert(error.message)
                            });
                    }
                }
                else alert("Este usuário não possui permissão para realizar essa ação")
            },
            mountedTable(page, filter = null) {
                this.filters.page = page;
                this.userCollection = [];
                this.access_token = "Bearer " + localStorage.access_token;
                const self = this;
                if(filter !== null){
                    this.filters.nome = filter.text ? filter.text : this.filters.fornecedor;
                    this.filters.with = ['hierarchy'];
                }
                self.loading = true;
                axios.get('/email_relatorio', {
                    headers: {
                        "Authorization": self.access_token
                    },
                    params: this.filters
                })
                    .then(function (response) {
                        self.loading = false;
                        self.userCollection = response.data.data;
                        self.currentPage = response.data.meta.current_page;
                        self.perPage = response.data.meta.per_page;
                        self.totalRows = response.data.meta.total;
                    })
                    .catch(function (error) {
                        self.loading = false;
                       alert(error);
                    })
            }

        },
        mounted: function () {
            this.mountedTable(1);
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .btn-table-inner-edit {
        color: #ffffff;
        border: solid 1px #28a745;
        border-radius: .25rem;
        background: #28a745;
        padding: .5rem;
    }

    .btn-table-inner-edit:hover {
        color: #28a745;
        border-color: #28a745;
        background-color: #ffffff;
        cursor: pointer;
    }

    .btn-table-inner-delete {
        color: #ffffff;
        border: solid 1px #ffc107;
        border-radius: .25rem;
        background: #ffc107;
        padding: .5rem;
    }

    .btn-table-inner-delete:hover {
        color: #ffc107;
        border-color: #ffc107;
        background-color: #ffffff;
        cursor: pointer;
    }

    .btn-table-inner-disable {
        color: #ffffff;
        border: solid 1px #dee2e6;
        border-radius: .25rem;
        background: #dee2e6;
        padding: .5rem;
    }
    @media print{
        .on-print {
            display: none;
        }
    }
</style>
