<template>
    <div id="FilterType2">
        <div class="card">
<!--            <b-input-group class="input-box">-->
<!--                <template v-slot:prepend>-->
<!--                    <b-input-group-text class="input-prepend">-->
<!--                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"-->
<!--                             xmlns="http://www.w3.org/2000/svg">-->
<!--                            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>-->
<!--                        </svg>-->
<!--                    </b-input-group-text>-->
<!--                </template>-->
<!--                <b-form-input-->
<!--                        class="input-filter"-->
<!--                        id="filterPedido"-->
<!--                        placeholder="Pedido"-->
<!--                        type="search"-->
<!--                        v-model="filter.pedido"-->
<!--                ></b-form-input>-->
<!--            </b-input-group>-->
            <label>Selecione um ou mais fornecedores:</label>
            <BodyTag event="tag-change" v-on:tag-change="tagChange($event)">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" data-v-14b21170="" data-v-3264a8d8=""></path>
                <circle cx="12" cy="7" data-v-14b21170="" data-v-3264a8d8="" r="4"></circle>
            </BodyTag>
            <b-btn v-on:click="buttonSubmit" variant="success">Pesquisar</b-btn>
        </div>
    </div>
</template>

<script>
    import BodyTag from "@/components/BodyTag";

    export default {
        name: "FilterCarteiraClientes",
        components: {
            BodyTag,
        },
        props: {
            event: String
        },
        data: function () {
            return {
                filter: {fornecedor: [], pedido: ''},
            }
        },
        methods: {
            tagChange(event) {
                this.filter.fornecedor = event;
            },
            buttonSubmit() {
                this.$emit(this.event, this.filter);
            }
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    label {
        color: #999;
    }

    .card {
        border-color: #28a745;
        padding: 1em;
        margin-bottom: 4em;
    }

    .btn-success:hover {
        border-color: #28a745;
        background: none;
        color: #28a745;
    }

    .input-prepend {
        color: #ffffff;
        background-color: #28a745;
        border-color: #28a745;
    }

    .input-filter {
        border-color: #dee2e6;
        color: #212529;
    }

    .input-filter:focus {
        border-color: #28a745;
        box-shadow: none;
    }
</style>
