<template>
    <div id="CarteiraCliente">
        <BodyTitle @exportExcel="handleExcel" :exporting="exporting" :export-excel="true" title="Carteira de Fornecedores"></BodyTitle>

        <FilterCarteiraClientes class="on-print" event="filter-change" v-on:filter-change="filterChange($event)"></FilterCarteiraClientes>

        <div class="on-print-padding">
            <div v-for="(client, index) in client_collection" v-bind:key="client.fornecedor" >
                <CollapseClient :client="client" :index='index'/>
            </div>
        </div>
        <b-pagination
                class="on-print"
                v-show="!loading && client_collection.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="fieldTable"
                @input="search"
        ></b-pagination>
        <p v-if="client_collection.length === 0 && !loading && filter !== null" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>
    </div>
</template>

<script>
    import FilterCarteiraClientes from "@/components/FilterCarteiraClientes";
    import CollapseClient from "@/components/CollapseClient";
    import BodyTitle from "@/components/BodyTitle";
    import axios from "@/axios";

    export default {
        name: "CarteiraCliente",
        components: {
            BodyTitle,
            CollapseClient,
            FilterCarteiraClientes
        },
        data() {
            return {
                client_collection: [],
                url:'/report/semaforo',
                access_token: "Bearer " + localStorage.access_token,
                filter: {
                    fornecedor: null
                },
                loading: false,
                exporting: false,
                currentPage: 1,
                perPage: 3,
                totalRows: 0,
                filters:null,
            }
        },
        methods: {
            handleExcel() {
                let filter = {
                    fornecedor: this.filter.fornecedor || null,
                };
                const self = this;
                this.exporting = true;
                filter.page = 1;
                filter.perPage = false;
                axios.get(self.url, {
                    params: filter,
                    headers: {
                        "Authorization": self.access_token,
                        "Accept": "application/xls"
                    }
                }).then(function (response) {
                    window.open(response.data.data.path);
                    self.exporting = false;
                }, (err) => {
                    self.exporting = false;
                });
            },
            filterChange(event) {
                this.filter = event;
                this.search();
            },
            search(page){
                this.filters = {
                    page: page,
                    fornecedor: this.filter.fornecedor || null,
                }

                let self = this;
                self.loading = true;
                self.client_collection = [];
                axios.get(self.url, {
                    params: this.filters,
                    headers: {
                        "Authorization": this.access_token
                    }
                })
                    .then(function (response) {
                        self.currentPage = response.data.current_page;
                        self.perPage = response.data.per_page;
                        self.totalRows = response.data.total;
                        self.client_collection = response.data.data;
                        self.loading = false;
                    });
            }
        },
    }
</script>

<style scoped>
    @media print {
        .on-print {
            display: none;
        }
    }
</style>
