<template>
    <div id="FollowUpCard">
        <div class="card-box">
            <div class="card-header">
                <BodyTitle @exportExcel="handleExcel" :export-excel="true" :followUpPedidos="true" :title="title"></BodyTitle>
            </div>

            <div class="card-body">
                <TableFollowUp
                        :enable-class="enableClass"
                        :items="itemsCollection"
                        :basic-fields="basicFields"
                        :item-fields="itemFields"
                        :modal-id="modalId"
                        @row-selected="onRowSelected($event)"
                        v-bind:key="tableUpdate"
                        @search-with-filter="$emit('search-with-filter',$event)"
                        :disableCheckFilters="disableCheckFilters"
                        :removeFornecedor="removeFornecedor"
                ></TableFollowUp>

                <div class="align-left on-print" v-if="removeExplanationText === undefined || removeExplanationText !== true">
                    <b>Clique nas linhas da tabela para adicionar detalhes sobre:</b>
                    <ul>
                        <li>Nº da nota fiscal</li>
                        <li>Data de emissão da nota fiscal</li>
                        <li>Quantidade faturada</li>
                        <li>PDF da nota fiscal faturada</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableFollowUp from "@/components/FollowUpTable";
    import BodyTitle from "@/components/BodyTitle";

    export default {
        name: "FollowUpCard",
        components: {
            BodyTitle,
            TableFollowUp
        },
        props: {
            title: String,
            itemsCollection: Array,
            basicFields: Array,
            itemFields: Array,
            modalId: String,
            enableClass: Boolean,
            tableUpdate: Number,
            disableCheckFilters: Boolean,
            removeFornecedor: Boolean,
           removeExplanationText: Boolean
        },
        methods: {
            handleExcel() {
                this.$emit('exportExcel');
            },
            onRowSelected(selected) {
                this.$emit('row-selected', selected)
            }
        }
    }
</script>

<style scoped>
    #FollowUpCard {
        padding: 1.5em;
    }

    .card-box {
        background-color: rgba(255, 255, 255, 1);
        border: solid 1px #28a745;
        border-radius: .5em;
        height: auto;
        color: #212529;
        font-size: 14px;
        text-align: center;
    }

    .card-body {
        padding: 2em 2em 1em 2em;
    }

    .card-header {
        border-bottom: .4em solid #28a745;
        text-align: left;
    }

    .align-left {
        padding-top: 1em;
        text-align: left;
    }

    @media print{
        .on-print {
            display: none;
        }
    }
</style>
