<script>
    import {Bar} from 'vue-chartjs'

    export default {
        name: "ChartBar",
        extends: Bar,
        data() {
            return {
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: true
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        props: {
            datacollection: Object
        },
        mounted() {
            this.renderChart(this.datacollection, this.options);
        },
    }
</script>

<style>
</style>
