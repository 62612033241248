<template>
    <div>
        <b-modal
                :id="id"
                @hidden="resetModal"
                @ok="handleOk"
                @show="resetModal"
                cancelTitle='Cancelar'
                cancelVariant="outline-secondary"
                centered
                header-bg-variant="success"
                header-text-variant="light"
                okTitle="Salvar"
                okVariant="success"
                ref="modal"
                size="xl"
                title="Detalhes do Pedido"
        >

            <b-table :fields="fieldsModal" :items="selected" class="table-padding" hover responsive="sm"
                     small></b-table>

            <form @submit.stop.prevent="handleSubmit" ref="form">
                <b-form-group
                        :state="statusAtualState"
                        label="Clique e selecione aqui qual é o status atual do pedido acima:"
                        label-for="statusAtual"
                >
                    <b-radio-group
                        :options="optionsStatus"
                        :state="statusAtualState"
                        id="statusAtual"
                        required
                        v-model="statusAtual">
                    </b-radio-group>
                </b-form-group>

                <div v-if="statusAtual === 'Faturado'">
                    <b-form-group
                            :state="numNFState"
                            label="Número da nota fiscal:"
                            label-for="numNF"
                    >
                        <b-form-input
                                :state="numNFState"
                                type="number"
                                id="numNF"
                                required
                                v-model="numNF"
                        />
                    </b-form-group>

                    <b-form-group
                            :state="dataEmissaoState"
                            label="Data de emissão da nota fiscal:"
                            label-for="dataEmissao"
                    >
                        <b-form-input
                                :state="dataEmissaoState"
                                id="dataEmissao"
                                required
                                type="date"
                                v-model="dataEmissao"
                        />
                    </b-form-group>

                    <b-form-group
                            :state="qtdFaturadaState"
                            label="Quantidade faturada:"
                            label-for="qtdFaturada"
                    >
                        <b-form-input
                                :state="qtdFaturadaState"
                                id="qtdFaturada"
                                required
                                type="number"
                                v-model="qtdFaturada"
                        />
                    </b-form-group>

                    <b-form-group
                            label="PDF da nota fiscal faturada (opcional):"
                            label-for="notaFiscal"
                    >
                        <b-form-file
                                id="notaFiscal"
                                v-model="notaFiscal"
                                placeholder="Escolha um arquivo ou arraste e solte aqui..."
                                drop-placeholder="Solte aqui..."
                                accept=".pdf"
                                browse-text="Pesquisar"
                        ></b-form-file>
                    </b-form-group>
                </div>

                <div v-else-if="statusAtual === 'Previsão'">
                    <b-form-group
                            :state="dataPrevisaoState"
                            label="Data de previsão da entrega:"
                            label-for="dataPrevisao"
                    >
                        <b-form-input
                                id="dataPrevisao"
                                type="date"
                                v-model="dataPrevisao"
                        />
                    </b-form-group>

                    <b-form-group
                            :state="observacoesState"
                            label="Observações:"
                            label-for="observacoes"
                            :description="'caracteres: ' + (70 - observacoes.length)"
                    >
                        <b-form-input
                                :state="observacoesState"
                                maxlength="70"
                                id="observacoes"
                                required
                                v-model="observacoes"
                        />
                    </b-form-group>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import axios from "@/axios";

    export default {
        name: "DetalhesPedido",
        props: {
            id: String,
            selected: Array,
        },
        computed: {
            statusAtualState() {
                return this.statusAtual.length >= 1
            },
            numNFState() {
                return this.numNF.length >= 1
            },
            dataEmissaoState() {
                return this.dataEmissao.length >= 1
            },
            qtdFaturadaState() {
                return this.qtdFaturada.length >= 1
            },
            dataPrevisaoState() {
                return this.dataPrevisao.length >= 1
            },
            observacoesState() {
                return this.observacoes.length >= 1
            },

        },
        data() {
            return {
                statusAtual: '',
                numNF: '',
                dataEmissao: '',
                dataPrevisao: '',
                qtdFaturada: '',
                observacoes: '',
                hash: '',
                notaFiscal: null,

                optionsStatus: [
                    {text: 'Faturado', value: 'Faturado'},
                    {text: 'Previsão de Entrega', value: 'Previsão'}
                ],

                submittedNames: [],

                fieldsModal: [
                    {key: 'pedido', sortable: false},
                    {key: 'codMaterial', sortable: false,},
                    {key: 'dataPedido', sortable: false,},
                    {key: 'dataEntrega', sortable: false,},
                    {key: 'diasAtraso', sortable: false,},
                    {key: 'quantidade', sortable: false,},
                    {key: 'saldo', sortable: false,},
                ],
            }
        },
        methods: {
            resetModal() {
                // eslint-disable-next-line no-console
                this.statusAtual = '';
                this.numNF = '';
                this.dataEmissao = '';
                this.qtdFaturada = '';
                this.dataPrevisao = '';
                this.observacoes = '';
                this.notaFiscal = null
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.handleSubmit()
            },
            handleSubmit() {
                if (this.statusAtual.length < 1 ||
                    (this.statusAtual.toUpperCase() === 'FATURADO' && (this.numNF.length < 1 || this.dataEmissao.length < 1 || this.qtdFaturada.length < 1)) ||
                    (this.statusAtual.toUpperCase() === 'PREVISÃO' && (this.dataPrevisao.length < 1 || this.observacoes.length < 1))) {
                    return
                }

                let formData = new FormData();

                for (let prop in this.selected[0]) {
                    formData.set(prop, this.selected[0][prop]);
                }

                if (this.notaFiscal != null) {
                    formData.set("file", this.notaFiscal,"nfe.pdf");
                }

                formData.set("status", this.statusAtual);
                formData.set("numNF", this.numNF);
                formData.set("dataEmissao", this.dataEmissao);
                formData.set("qtdFaturada", this.qtdFaturada);
                formData.set("dataPrevisao", this.dataPrevisao);
                formData.set("observacoes", this.observacoes);
                formData.set("hash", this.$route.params.hash);

                // let postSelected = this.selected[0];
                // postSelected.status = this.statusAtual;
                // postSelected.numNF = this.numNF;
                // postSelected.dataEmissao = this.dataEmissao;
                // postSelected.qtdFaturada = this.qtdFaturada;
                // postSelected.dataPrevisao = this.dataPrevisao;
                // postSelected.observacoes = this.observacoes;
                // postSelected.hash = this.$route.params.hash;
                //
                // if (this.notaFiscal != null) {
                //     let base64;
                //     let fileReader = new FileReader();
                //
                //     fileReader.onload = function(fileLoadedEvent) {
                //         base64 = fileLoadedEvent.target.result;
                //     };
                //     fileReader.readAsDataURL(this.notaFiscal);
                //
                //     postSelected.file = base64;
                // }

                let self = this;
                axios.post('/gravar-dados/pedido-status', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(function () {
                        self.$nextTick(() => {
                            self.$bvModal.hide(self.id);
                            self.$emit('table-update')

                        })
                    })
                    .catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    });
            }
        }
    }
</script>

<style scoped>
    .table-padding {
        padding-bottom: 1em;
    }
</style>
