<template>
    <div id="IndexHeader">
        <IndexUser v-bind:key="userUpdate" class="index-user-header"/>
        <b-nav align="right">
            <b-nav-item href="https://www.facebook.com/Klabin.SA/" target="_blank" title="Facebook">
                <svg version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"/>
                </svg>
            </b-nav-item>
            <b-nav-item href="https://www.instagram.com/klabin.sa/" target="_blank" title="Instagram">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48h192c61.76,0,112,50.24,112,112V352z"/>
                    <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/>
                    <circle cx="393.6" cy="118.4" r="17.056"/>
                </svg>
            </b-nav-item>
            <b-nav-item href="https://www.youtube.com/channel/UCqVPDan2oZDd1w2QZMLJAGg" target="_blank" title="Youtube">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"/>
                </svg>
            </b-nav-item>
            <b-nav-item href="https://www.linkedin.com/company/klabin/" target="_blank" title="Linkedin">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <rect height="352" width="114.496" y="160"/>
                    <path d="M426.368,164.128c-1.216-0.384-2.368-0.8-3.648-1.152c-1.536-0.352-3.072-0.64-4.64-0.896c-6.08-1.216-12.736-2.08-20.544-2.08c-66.752,0-109.088,48.544-123.04,67.296V160H160v352h114.496V320c0,0,86.528-120.512,123.04-32c0,79.008,0,224,0,224H512V274.464C512,221.28,475.552,176.96,426.368,164.128z"/>
                    <circle cx="56" cy="56" r="56"/>
                </svg>
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
    import IndexUser from "@/components/IndexUser";

    export default {
        name: "IndexHeader",
        components: {IndexUser},
        props: {
            userUpdate: Number
        },
        methods: {
            mouseEvent() {
                console.log("ok")
            }
        },
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: white;
        stroke: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        margin: 0;
    }
    .index-user-header {
        float: left;
    }
</style>
