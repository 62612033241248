<template>
    <div id="Login" v-bind:style="cursor_login">
        <div class="parallax"></div>
        <div class="card">
            <div class="card-header">
                <img alt="Klabin" src="../assets/logo-klabin.svg">
            </div>
            <div class="card-body">
                <div class="warning">
                    {{ warning_message }}
                </div>
                <form action="javascript:void(0);" @submit="login_submit">
                    <InputGroup id="user" name="user" placeholder="Usuário" type="text" event="user-change" v-on:user-change="userChange($event)">
                        <span>
                            <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </span>
                    </InputGroup>

                    <InputGroup :type="password_type" id="pass" name="pass" placeholder="Senha" event="pass-change" v-on:pass-change="passChange($event)">
                        <span @click.prevent="password_visible()" class="svg-hover" v-html="password_svg"></span>
                    </InputGroup>

                    <nav class="nav nav-pills nav-justified">
                        <button type="button" class="nav-item btn btn-outline-secondary" style="margin-right: 1em"
                                v-on:click="login_reset()">Redefinir
                        </button>
                        <button type="submit" class="nav-item btn btn-outline-success form-control">
                            Entrar
                        </button>
                    </nav>
                </form>
            </div>
            <div class="card-footer">
                <b>Copyright 2020 © Klabin S.A. CNPJ: 89.637.490/0001-45</b>
            </div>
        </div>
    </div>

</template>

<script>
    import InputGroup from "@/components/BodyInput";
    import router from "@/router";
    import axios from 'axios';

    export default {
        name: 'Login',
        components: {
            InputGroup
        },
        props: {},
        data: function () {
            return {
                username: "",
                userpass: "",
                warning_message: "",
                cursor_login: "cursor: default",
                password_type: "password",
                password_svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"1.5em\" height=\"1.5em\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather\"><path d=\"M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24\"></path><line x1=\"1\" y1=\"1\" x2=\"23\" y2=\"23\"></line></svg>"
            }
        },
        methods: {
            userChange(event) {
                this.username = event;
            },
            passChange(event) {
                this.userpass = event;
            },
            password_visible: function () {
                if (this.password_type === "password") {
                    this.password_type = "text";
                    this.password_svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"1.5em\" height=\"1.5em\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather\"><path d=\"M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z\"></path><circle cx=\"12\" cy=\"12\" r=\"3\"></circle></svg>";
                } else {
                    this.password_type = "password";
                    this.password_svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"1.5em\" height=\"1.5em\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather\"><path d=\"M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24\"></path><line x1=\"1\" y1=\"1\" x2=\"23\" y2=\"23\"></line></svg>";
                }
            },

            login_reset: function () {
                this.username = null;
                this.userpass = null;
            },

            login_submit: function () {
                const self = this;
                self.cursor_login = "cursor: wait";
                axios.post(
                    '/login',
                    {
                        "username": this.username,
                        "password": this.userpass
                    },
                    {
                        timeout: 5000,
                        baseURL: process.env.VUE_APP_BASE_URL_LOGIN,
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    })
                    .then(function (response) {
                        localStorage.access_token = response.data.access_token;
                        let access_token = "Bearer " + response.data.access_token;

                        axios.get(
                            '/user',
                            {
                                timeout: 5000,
                                baseURL: process.env.VUE_APP_BASE_URL_LOGIN,
                                headers: {
                                    "Authorization": access_token
                                }
                            })
                            .then(function (response) {
                                localStorage.user_permission = response.data.type;
                                localStorage.user_name = response.data.name;
                                localStorage.user_id = response.data.id;
                                router.push("relatorio-pedidos");
                            })
                            .catch(function (error) {
                                console.log(error);
                                router.push("login");
                            });
                    })
                    .catch(function (error) {
                        if(error.response.status !== undefined){
                            switch (error.response.status) {
                                case 400 :
                                    self.warning_message = "Todos os campos são obrigatórios!";
                                    break;
                                case 401 :
                                    self.warning_message = "Usuário e/ou Senha incorreto!";
                                    break;
                                default :
                                    self.warning_message = "Não foi possível realizar o login. Tente novamente mais tarde!";
                                    break;
                            }
                        }else{
                            self.warning_message = "Ocorreu um erro. Tente novamente!";
                        }

                        self.cursor_login = "cursor: default";
                    });
            },
        },
        mounted() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_permission');
            localStorage.removeItem('user_name');
        }
    }
</script>

<style scoped>
    #login {
        font-family: 'Glyphicons Halflings', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
    }

    span {
        height: 24px;
    }

    img {
        height: 6em;
        width: auto;
    }

    .parallax {
        background-image: url("../assets/banner-klabin.jpg");
        filter: blur(8px);
        -webkit-filter: blur(8px);
        height: 100vh;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .card {
        background-color: rgba(255, 255, 255, 1);
        border-color: #28a745;
        border-radius: .5em;
        color: #000000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }

    .warning {
        padding-bottom: 1em;
        font-size: 13px;
        color: red;
    }

    .card-body {
        padding: 2em 2em 1em 2em;
    }

    .card-header {
        border-bottom: .4em solid #28a745;
        text-align: center;
    }

    .card-footer {
        border-top: 0;
        background-color: #28a745;
        border-color: #28a745;
        text-align: center;
        /*color: #beffcd;*/
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
    }

    .nav {
        margin: 2em 0 2em 0;
    }

    .btn {
        margin-top: 1em;
    }

    .svg-hover:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        .card {
            width: 90%;
        }
    }
</style>
