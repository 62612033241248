<template>
    <div>
        <BodyTitle title="Histórico dos Fornecedores"></BodyTitle>

        <FilterHistoricoFornecedores class="on-print" event="filter-change" v-on:filter-change="filterChange($event)"></FilterHistoricoFornecedores>

        <div class="padding-bottom on-print-padding" v-if="faturadosCollection.datasets.length > 0">
            <h4>Total de Pedidos Faturado</h4>
            <ChartBar v-bind:datacollection="faturadosCollection" :key="faturadosKey"/>
        </div>

        <div class="padding-bottom on-print-padding" v-if="atrasadosCollection.datasets.length > 0">
            <h4>Total de Pedidos Atrasados</h4>
            <ChartBar :datacollection="atrasadosCollection" :key="atrasadosKey"/>
        </div>

        <p v-if="faturadosCollection.datasets.length === 0 && atrasadosCollection.datasets.length === 0 && !loading && filter !== null" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>
    </div>
</template>

<script>
    import FilterHistoricoFornecedores from "@/components/FilterHistoricoFornecedores";
    import ChartBar from "@/components/ChartBar";
    import BodyTitle from "@/components/BodyTitle";
    import axios from "@/axios";

    export default {
        name: "HistoricoFornecedores",
        components: {
            FilterHistoricoFornecedores,
            BodyTitle,
            ChartBar,
        },
        data: function() {
            return {
                access_token: '',
                faturadosKey: 0,
                atrasadosKey: 0,
                historicoKey: 0,
                filter: null,
                faturadosCollection: {
                    datasets: [],
                    labels: [''],
                },
                atrasadosCollection: {
                    datasets: [],
                    labels: [''],
                },
                loading: false,
            }
        },
        methods: {
            filterChange(event) {
                this.filter = event;
                this.chartCreate();
            },
            chartCreate() {
                this.loading = true;
                let letters = '0123456789ABCDEF';
                let color = '#';
                const self = this;

                this.access_token = "Bearer " + localStorage.access_token;
                let filter = {
                    fornecedor: this.filter.fornecedor,
                    dataPedidoBegin: this.filter.inicio || null,
                    dataPedidoEnd: this.filter.fim || null,
                };
                self.faturadosCollection.datasets = [];
                self.atrasadosCollection.datasets = [];

                axios.get('/report/faturados', {
                    params: filter,
                    headers: {
                        "Authorization": self.access_token
                    }
                })
                    .then(function (response) {
                        response.data.data.forEach( function (element) {
                            for (let i = 0; i < 6; i++) {
                                color += letters[Math.floor(Math.random() * 16)];
                            }
                            self.faturadosCollection.datasets.push({label: [element.fornecedor], data: [element.qtd_pedidos], backgroundColor: color});
                            color = '#';
                        });
                        self.faturadosKey++;
                        self.loading = false;

                        axios.get('/report/atrasados', {
                            params: filter,
                            headers: {
                                "Authorization": self.access_token
                            }
                        })
                            .then(function (response) {
                                response.data.data.forEach( function (element) {
                                    for (let i = 0; i < 6; i++) {
                                        color += letters[Math.floor(Math.random() * 16)];
                                    }
                                    self.atrasadosCollection.datasets.push({label: [element.fornecedor], data: [element.qtd_pedidos], backgroundColor: color});
                                    color = '#';
                                });
                                self.atrasadosKey++;
                            })
                            .catch(function (error) {
                                // eslint-disable-next-line no-console
                                console.log(error);
                                self.atrasadosKey++;
                            });
                    })
                    .catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        self.faturadosKey++;
                        self.loading = false;
                    });
            }
        },
    }
</script>

<style scoped>
    h4 {
        text-align: left;
        padding-bottom: 1em;
    }

    .padding-bottom {
        padding-bottom: 5em;
    }

    @media print {
        .on-print {
            display: none;
        }
        .on-print-padding {
            padding-top: 5em;
        }
    }
</style>
