<template>
    <div id="Index">
        <div @mouseover="mouseHoverSidebar" class="index-sidebar">
            <IndexSidebar :width-img="widthImg" v-on:fixed-menu="changeMenu"></IndexSidebar>
        </div>
        <div @mouseover="mouseHoverBody">
            <div :style="'left:' + leftBody + ';width: calc(100vw - ' + leftBody + ')'" class="sidebar-mask"></div>
            <div :style="'left:' + leftBody + ';width: calc(100vw - ' + leftBody + ')'" class="index-header">
                <IndexHeader :userUpdate="userKey"></IndexHeader>
            </div>
            <div :style="'left:' + leftBody + ';width: calc(100vw - ' + leftBody + ')'" class="index-body">
                <router-view></router-view>
            </div>
        </div>

    </div>
</template>

<script>
    import IndexSidebar from "@/components/IndexSidebar";
    import IndexHeader from "@/components/IndexHeader";
    import router from "@/router";

    export default {
        name: "Index",
        components: {IndexHeader, IndexSidebar},
        data: function () {
            return {
                widthImg: '3.5em',
                leftBody: '3.5em',
                fixedMenu: false,
                access_token: '',
                userKey: 0,
            }
        },
        methods: {
            changeMenu() {
                this.fixedMenu = !this.fixedMenu;
                if (this.fixedMenu) {
                    this.leftBody = '19em';
                    this.widthImg = '100%';
                }
            },
            mouseHoverSidebar() {
                if (!this.fixedMenu) {
                    this.leftBody = '19em';
                    this.widthImg = '100%';
                }
            },
            mouseHoverBody() {
                if (!this.fixedMenu) {
                    this.leftBody = '3.5em';
                    this.widthImg = '3.5em';
                }
                this.userKey++;
            },
        },
        mounted() {
            if (!localStorage.access_token){
                router.push('/login')
            }
        },
    }
</script>

<style scoped>
    .index-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 19em;
        padding: 0;
        background-color: #ffffff;
        overflow-x: auto;
    }

    .index-header {
        position: absolute;
        top: 0;
        background-color: #28a745;
        height: 3em;
        padding: 0 1em 0 0;
        transition: left 1s, width 1s;
    }

    .index-body {
        position: absolute;
        top: 3em;
        background-color: #ffffff;
        padding: 0 2em 2em 2em;
        border-left: solid 1px #28a745;
        transition: left 1s, width 1s;
    }

    .sidebar-mask {
        position: absolute;
        top: 3em;
        height: calc(100vh - 3em);
        background-color: #ffffff;
        padding: 0 2em 2em 2em;
        border-left: solid 1px #28a745;
        transition: left 1s, width 1s;
    }

    @media print{
        .index-sidebar, .index-header {
            display: none;
        }
        .index-body, .sidebar-mask {
            top: 0;
            left: 0 !important;
            width: 100vw !important;
            border: none;
        }
    }
</style>
