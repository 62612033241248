import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/screens/Login";
import PedidosMultas from "@/menu/MultasPedidos";
import HistoricoFornecedores from "@/menu/HistoricoFornecedores";
import CarteiraCliente from "@/menu/CarteiraCliente";
import FollowUpPedidos from "@/screens/FollowUpPedidos";
import FollowUpMultas from "@/screens/FollowUpMultas";
import Index from "@/screens/Index";
import Pedidos from "@/menu/RelatorioPedidos";
import EnvioEmails from "@/menu/EnvioEmails";
import RelatorioAutomatico from "@/menu/RelatorioAutomatico";
import Multas from "@/menu/RelatorioMultas";
import MultasConsolidadas from "@/menu/RelatorioMultasConsolidadas";
import HistoricoMultas from "@/menu/RelatorioHistoricoMultas";
import CadastrarUsuario from "@/menu/CadastrarUsuario";
import UsuarioEdit from "./modal/UsuarioEdit";
import AlterarSenha from "./menu/AlterarSenha";

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/index',
            name: 'index',
            component: Index,
            children: [
                {
                    path: '/carteira-cliente',
                    name: 'carteira-cliente',
                    component: CarteiraCliente
                },
                {
                    path: '/historico-fornecedores',
                    name: 'historico-fornecedores',
                    component: HistoricoFornecedores
                },
                {
                    path: '/envio-de-emails',
                    name: 'envio-de-emails',
                    component: EnvioEmails
                },
                {
                    path: '/pedidos-multas',
                    name: 'pedidos-multas',
                    component: PedidosMultas
                },
                {
                    path: '/relatorio-automatico',
                    name: 'relatorio-automatico',
                    component: RelatorioAutomatico
                },
                {
                    path: '/relatorio-pedidos',
                    name: 'relatorio-pedidos',
                    component: Pedidos
                },
                {
                    path: '/relatorio-multas',
                    name: 'relatorio-multas',
                    component: Multas
                },
                {
                    path: '/relatorio-multas-consolidadas',
                    name: 'relatorio-multas-consolidadas',
                    component: MultasConsolidadas
                },
                {
                    path: '/historico-multas',
                    name: 'historico-multas',
                    component: HistoricoMultas
                },
                {
                    path: '/cadastro-usuario',
                    name: 'cadastro-usuario',
                    component: CadastrarUsuario
                },
                {
                    path: '/alterar-senha',
                    name: 'alterar-senha',
                    component: AlterarSenha
                },
            ]
        },

        {
            path: '/logout',
            name: 'logout',
            component: Login,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/',
            name: '',
            component: Login,
        },
        {
            path: '/follow-up-pedidos/:hash',
            // path: '/follow-up-pedidos',
            component: FollowUpPedidos,
        },
        {
            path: '/follow-up-multas/:hash',
            // path: '/follow-up-multas',
            component: FollowUpMultas,
        },
    ]
})
