<template>
    <div id="BodyTag">
        <b-form-group>
            <b-form-tags
                    v-model="value"
                    remove-on-delete
                    no-outer-focus
                    class="mb-2 input-group-dropdown"
                    @input="onTagInput"
            >
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                            <b-form-tag
                                    @remove="removeTag(tag)"
                                    :title="tag"
                                    :disabled="disabled"
                                    variant="success"
                            >{{ tag }}</b-form-tag>
                        </li>
                    </ul>
                    <b-dropdown class="form-control input-group-dropdown" variant="outline-success" menu-class="w-100">
                        <template v-slot:button-content>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="feather">
                                <slot></slot>
                            </svg>
                            Fornecedores
                        </template>
                        <b-dropdown-form @submit.stop.prevent="() => {}">
                            <b-form-group
                                    label-for="tag-search-input"
                                    label="Digite para filtrar"
                                    label-cols-md="auto"
                                    class="mb-0 dropdown-form-group"
                                    label-size="sm"
                                    :description="searchDesc"
                                    :disabled="disabled"
                            >
                                <b-form-input
                                        v-model="search"
                                        id="tag-search-input"
                                        type="search"
                                        size="md"
                                        autocomplete="off"
                                        placeholder="Nome do fornecedor"
                                ></b-form-input>
                            </b-form-group>
                        </b-dropdown-form>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item-button
                                v-for="option in availableOptions"
                                :key="option"
                                @click="onOptionClick({ option, addTag })"
                        >
                            {{ option }}
                        </b-dropdown-item-button>
                        <b-dropdown-text v-if="availableOptions.length === 0">
                            Não foram encontrados mais fornecedores.
                        </b-dropdown-text>
                    </b-dropdown>
                </template>
            </b-form-tags>
        </b-form-group>
    </div>
</template>

<script>
    import axios from "@/axios";

    export default {
        name: "BodyTag",
        props: {
            event: String,
            unique: Boolean,
        },
        data: function () {
            return {
                search: '',
                value: [],
                access_token: '',
                options: [],
            }
        },
        computed: {
            criteria() {
                return this.search.trim().toLowerCase();
            },
            availableOptions() {
                const criteria = this.criteria;
                const options = this.options.filter(opt => this.value.indexOf(opt) === -1);
                if (criteria) {
                    return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
                }
                return options;
            },
            searchDesc() {
                if (this.criteria && this.availableOptions.length === 0) {
                    return 'A busca não encontrou nenhenhum fornecedor.';
                }
                return '';
            }
        },
        methods: {
            onOptionClick({ option, addTag }) {
                addTag(option);
                this.search = '';
            },
            onTagInput() {
                let emitValue = [];
                this.value.forEach(function (element) {
                    emitValue.push(element.split(' - ').slice(0, 1).join(''));
                });
                if(this.unique && this.value.length > 1){
                    this.value = this.value[this.value.length-1]
                }
                this.$emit(this.event, emitValue);
            }
        },
        mounted: function () {
            this.access_token = "Bearer " + localStorage.access_token;

            let self = this;
            axios.get('/fornecedor', {
                headers: {
                    "Authorization": self.access_token
                }
            })
                .then(function (response) {
                    // let optionArray = [];
                    response.data.data.forEach( function (element) {
                        self.options.push(element.fornecedor);
                        // optionArray.push(element.fornecedor);
                    });
                    // self.options = optionArray.filter((a, b) => optionArray.indexOf(a) === b);
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                })
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        margin-right: 1em;
    }
    input:focus {
        border-color: #28a745 !important;
        box-shadow: none !important;
    }
    .input-group-dropdown {
        padding: 0;
        border: none;
    }
</style>
