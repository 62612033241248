<template>
    <div id="CadastrarDestinatario">
        <BodyTitle title="Destinatários para Relatório Automático"></BodyTitle>

        <FormButton text="Cadastrar Novo Destinatário" event="new-user" v-on:new-user="newUser" v-if="deletePermission()">
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
        </FormButton>
        <DestinatarioNovo :id="destinatarioNew" v-on:refresh-table="refreshTable()"></DestinatarioNovo>

        <DestinatarioRelatorioTable :refresh-table="tableKey"></DestinatarioRelatorioTable>
    </div>
</template>

<script>
    import BodyTitle from "@/components/BodyTitle";
import DestinatarioRelatorioTable from "@/components/DestinatarioRelatorioTable";
    import FormButton from "@/components/FormButton";
    import DestinatarioNovo from "@/modal/DestinatarioNovo";

    export default {
        name: "CadastrarDestinatario",
        components: {
            DestinatarioNovo,
            FormButton,
            DestinatarioRelatorioTable,
            BodyTitle
        },
        data() {
            return {
                destinatarioNew: "destinatarioNovo",
                tableKey: 0,
            }
        },
        methods: {
            newUser() {
                this.$bvModal.show(this.destinatarioNew);
            },
            refreshTable() {
                this.tableKey++;
            },
            deletePermission() {
                return localStorage.user_permission < 2
            },
        }
    }
</script>

<style scoped>
    new-user {
        margin: 3em;
    }
</style>
