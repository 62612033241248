<template>
    <div id="EventosEmailsTable">
        <!--<UserFilter v-on:filter-input-change="filter = $event" v-on:filter-check-change="mountedTable(1,$event)"></UserFilter>-->

        <b-table
                id="table"
                :fields="fields"
                :items="items"
                :per-page="perPage"
                :current-page="currentPage"
                hover
                no-border-collapse
                ref="selectableTable"
                responsive="lg"
                striped
        >
        <template #cell(enviado)="item">
            <div v-if="item.value">{{ formataData(item.value) }}</div>
            <div v-else>-</div>
        </template>
        <template #cell(entregue)="item">
            <div v-if="item.value">{{ formataData(item.value) }}</div>
            <div v-else>-</div>
        </template>
        <template #cell(acessado)="item">
            <div v-if="item.value">{{ formataData(item.value) }}</div>
            <div v-else>-</div>
        </template>
        <template #cell(respondido)="item">
            <div v-if="item.value">{{ formataData(item.value) }}</div>
            <div v-else>-</div>
        </template>
        <template #cell(erro_envio)="item">
            <div v-if="item.value">{{ formataData(item.value) }}</div>
            <div v-else>-</div>
        </template>
        </b-table>
        
        <p v-if="false" class="msg-length">Nenhuma informação encontrada.</p>
        
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>

        
    </div>
</template>

<script>
import { emit } from 'process';
import moment from 'moment';


    export default {
        name: "EventosEmailsTable",
        components: {
            //UserFilter,
        },
        props: {
            items: {},
            enableClass: {},
            lastPage: Number,
            perPage: Number,
        },
        data: () => {
            return {
                currentPage: 1,
                filter: '',
                loading: false,
                fields: [
                    { key: 'referencia', label: 'Referência', sortable: true, },
                    { key: 'codFornecedor', label: 'Cod Fornecedor', sortable: true, },
                    { key: 'pedido', label: 'Pedido', sortable: true, },
                    { key: 'fornecedor', label: 'Fornecedor', sortable: true },
                    { key: 'email', label: 'E-mail', sortable: true, },
                    { key: 'enviado', label: 'Enviado', sortable: true },
                    { key: 'entregue', label: 'Entregue', sortable: true },
                    { key: 'acessado', label: 'Acessado', sortable: true },
                    { key: 'respondido', label: 'Respondido', sortable: true },
                    { key: 'erro_envio', label: 'Erro no envio', sortable: true },
                ],
            }
        },

        watch: {
            items() {
                //console.log(this.items)
            },
            currentPage() {
                console.log('currentPage')
                this.$emit('page-change', this.currentPage);
            }
        },
        computed: {
        },
        methods: {
            pageChange(event) {
                console.log('pageChange')
                this.currentPage = event
                this.$emit('page-change', this.currentPage);
            },
            formataData(data) {
                return moment(data).format('DD/MM/YYYY HH:mm:ss')
            },
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .btn-table-inner-edit {
        color: #ffffff;
        border: solid 1px #28a745;
        border-radius: .25rem;
        background: #28a745;
        padding: .5rem;
    }

    .btn-table-inner-edit:hover {
        color: #28a745;
        border-color: #28a745;
        background-color: #ffffff;
        cursor: pointer;
    }

    .btn-table-inner-delete {
        color: #ffffff;
        border: solid 1px #ffc107;
        border-radius: .25rem;
        background: #ffc107;
        padding: .5rem;
    }

    .btn-table-inner-delete:hover {
        color: #ffc107;
        border-color: #ffc107;
        background-color: #ffffff;
        cursor: pointer;
    }

    .btn-table-inner-disable {
        color: #ffffff;
        border: solid 1px #dee2e6;
        border-radius: .25rem;
        background: #dee2e6;
        padding: .5rem;
    }
    @media print{
        .on-print {
            display: none;
        }
    }
</style>
