<template>
   <b-form-group class="on-print">
      <div class="row">
         <div class="col-sm-12 pl-0">
            <div>
            <b-form inline>
               <b-input-group class="input-box col-12 col-md-3 pr-3">
                  <template v-slot:prepend>
                     <b-input-group-text class="input-prepend">
                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                           <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                     </b-input-group-text>
                  </template>
                  <b-form-select 
                  v-model="filterPeriodo" 
                  :options="options"
                  />
               </b-input-group>

               <b-input-group class="input-box col-12 col-md-2">
                  <template v-slot:prepend>
                     <b-input-group-text class="input-prepend">
                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                           <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                     </b-input-group-text>
                  </template>
                  <b-form-input
                      class="input-filter"
                      id="filterPedido"
                      placeholder="Pedido"
                      type="search"
                      v-model="filterPedido"
                  ></b-form-input>
               </b-input-group>

               <b-input-group class="input-box col-12 col-md-7 pr-0">
                  <template v-slot:prepend>
                     <b-input-group-text class="input-prepend">
                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                           <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                     </b-input-group-text>
                  </template>
                  <b-form-input
                      class="input-filter"
                      id="filterInput"
                      placeholder="Fornecedor"
                      type="search"
                      v-model="filterInput"
                  ></b-form-input>
               </b-input-group>
            </b-form>
            </div>
         </div>
      </div>
      <b-input-group>
         <b-btn class="w-100 mt-2"
                v-on:click="$emit('filter-check-change', {status:filterCheck, text: filterInput, pedido: filterPedido})"
                variant="success">Pesquisar
         </b-btn>
      </b-input-group>
   </b-form-group>
</template>

<script>
import axios from "@/axios";

export default {
   name: "FollowUpFilter",
   components: {
   },
   props: {
      disableCheckFilters: Boolean,
      removeFornecedor: Boolean,
      hasDateFilter: Boolean,
   },
   data: function () {
      return {
         access_token: '',
         filterInput: '',
         filterPedido: '',
         filterPeriodo: '',
         filterCheck: [],
         options: [
          { value: '', text: 'Período' },
          { value: 'January-February', text: 'Janeiro - Fevereiro' },
          { value: 'March-April', text: 'Março - Abril' },
          { value: 'May-June', text: 'Maio - Junho' },
          { value: 'July-August', text: 'Julho - Agosto' },
          { value: 'September-October', text: 'Setembro - Outubro' },
          { value: 'November-December', text: 'Novembro - Dezembro' },
        ],
      }
   },
   methods: {

   },
   watch: {
      filterPedido: function (new_val, old_val) {
         this.$emit("pedido-change", {pedido: this.filterPedido});
      },
      filterInput: function (new_val, old_val) {
         //fornecedor
         this.$emit("fornecedor-change", {fornecedor: this.filterInput});
      },
      filterPeriodo: function (new_val, old_val) {
         //fornecedor
         this.$emit("periodo-change", {periodo: this.filterPeriodo});
      }
   },
   mounted() {
      this.access_token = "Bearer " + localStorage.access_token;
   }
}
</script>

<style scoped>
svg {
   width: 1.5em;
   height: 1.5em;
   fill: none;
   stroke: currentColor;
   stroke-width: 2px;
   stroke-linecap: round;
   stroke-linejoin: round;
}

.input-prepend {
   color: #ffffff;
   background-color: #28a745;
   border-color: #28a745;
}

.input-filter {
   border-color: #dee2e6;
   color: #212529;
}

.input-filter:focus {
   border-color: #28a745;
   box-shadow: none;
}

@media print {
   .on-print {
      display: none;
   }
}
</style>
