import {format} from "date-fns";
<template>
    <div>
        <CardFollowUp
                @exportExcel="handleExcel"
                :items-collection="itemsCollection"
                :modal-id="modalId"
                @row-selected="selected = $event"
                :enable-class="true"
                title="Follow Up de Pedidos"
                :table-update="tableKey"
                @search-with-filter="tableCreate(1,$event)"
                :removeFornecedor="true"
                :removeExplanationText="false"
        ></CardFollowUp>
        <b-pagination
                class="on-print"
                v-show="!loading && itemsCollection.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="fieldTable"
                @input="tableCreate"
        ></b-pagination>
        <p v-if="itemsCollection.length === 0 && !loading" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>
        <DetalhesPedido :id="modalId" :selected="selected" v-on:table-update="tableUpdate()"></DetalhesPedido>
    </div>
</template>

<script>
    import CardFollowUp from "@/components/FollowUpCard";
    import DetalhesPedido from "@/modal/DetalhesPedido";
    import axios from "../axios";
    import {format} from 'date-fns';

    export default {
        name: "FollowUpPedidos",
        components: {
            DetalhesPedido,
            CardFollowUp
        },
        data: function () {
            return {
                selected: [],
                url: '/buscar-dados/pedido-status/',
                modalId: "DetalhesPedidos",
                itemsCollection: [],
                tableKey: 0,
                loading: false,
                currentPage: 1,
                perPage: 3,
                totalRows: 0,
                filters: {
                    page: 1,
                    pedido_status: null,
                    fornecedor: null,
                    pedido: null,
                },
            }
        },
        methods: {
            handleExcel() {
                const self = this;
                let filters = this.filters;
                filters.page = 1;
                filters.perPage = false;
                axios.get(self.url + this.$route.params.hash, {
                    headers: {
                        "Accept": "application/xls"
                    },
                    params: filters,
                }).then(function (response) {
                    window.open(response.data.data.path);
                });
            },
            tableUpdate() {
                this.tableCreate(1);
                this.tableKey++;
            },
            money(value){
                let val = (value).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            dateTimeToDate(value){
                if(value !== null){
                    return format(new Date(value), 'dd/MM/yyyy');
                }
                return null;
            },
            tableCreate(page, filter = null) {
                this.filters.page = page;
                if(filter !== null){
                    this.filters.pedido_status = filter.status ? filter.status : null;
                    this.filters.fornecedor = filter.text ? filter.text : null;
                    this.filters.pedido = filter.pedido && filter.pedido !== '' ? filter.pedido : null;
                }
                const self = this;
                this.filters.page = page;
                self.loading = true;
                axios.get(self.url + this.$route.params.hash, {
                    params: this.filters
                })
                    .then(function (response) {
                        self.currentPage = response.data.meta.current_page;
                        self.perPage = response.data.meta.per_page;
                        self.totalRows = response.data.meta.total;
                        self.itemsCollection = response.data.data;
                        self.loading = false;
                        self.itemsCollection.forEach( function (element) {
                            if (element.dataPedido != null) {
                                element.dataPedido = self.dateTimeToDate(element.dataPedido);
                            }
                            if (element.dataEntrega != null) {
                                element.dataEntrega = self.dateTimeToDate(element.dataEntrega);
                            }
                            if (element.dataCriacao != null) {
                                element.dataCriacao = self.dateTimeToDate(element.dataCriacao);
                            }
                            if (element.dataPrevisao != null) {
                                element.dataPrevisao = self.dateTimeToDate(element.dataPrevisao);
                            }
                            if (element.dataEmissao != null) {
                                element.dataEmissao = self.dateTimeToDate(element.dataEmissao);
                            }
                            if (element.valorMulta != null) {
                                element.valorMulta = self.money(element.valorMulta);
                            }
                            if (element.valorUnitario != null) {
                                element.valorUnitario = self.money(element.valorUnitario);
                            }
                        });
                    })
                    .catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    })
            }
        },
        mounted: function () {
            this.tableCreate(1);
        }
    }
</script>

<style scoped>

</style>
