<template>
    <div id="IndexSidebar">
        <IndexButton :event="fixedMenu" :style-svg="rotateSvg" :text="fixedText" route="" v-on:fixed-menu="fixedChange"
            style="margin-top: 0em; height: 3em;">
            <polyline points="13 17 18 12 13 7"></polyline>
            <polyline points="6 17 11 12 6 7"></polyline>
        </IndexButton>
        <b-row class="header-image">
            <b-col>
                <span>
                    <a href="https://klabin.com.br/">
                        <img :style="'width: ' + widthImg" alt="Klabin" src="../assets/logo-klabin.svg">
                    </a>
                </span>
            </b-col>
        </b-row>
        <b-row class="menu-row" cols="1">
            <b-col class="menu-col">
                <h6 class="menu-title">
                    <span>Relatórios</span>
                </h6>
                <IndexButton event="" route="relatorio-pedidos" style-svg="" text="Pedidos">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect height="4" rx="1" ry="1" width="8" x="8" y="2"></rect>
                </IndexButton>
               <IndexButton event="" route="relatorio-multas" style-svg="" text="Multas">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect height="4" rx="1" ry="1" width="8" x="8" y="2"></rect>
                </IndexButton>
                <IndexButton event="" route="relatorio-multas-consolidadas"
                style-svg="" text="Multas Consolidadas">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2 "></path>
                    <path height="4" d="M11 16l-3-3 3-3M16 16l-3-3 3-3"/>
                    <rect height="4" rx="1" ry="1" width="8" x="8" y="2"></rect>
                </IndexButton>
                <IndexButton event="" route="relatorio-automatico" class="ma-auto" style-svg="" text="Relatório Automático">
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </IndexButton>
<!--               <IndexButton event="" route="historico-multas" style-svg="" text="Histórico de Multas">-->
<!--                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>-->
<!--                    <rect height="4" rx="1" ry="1" width="8" x="8" y="2"></rect>-->
<!--                </IndexButton>-->
                <hr>
            </b-col>

            <b-col class="menu-col">
                <h6 class="menu-title">
                    <span>Dashboard</span>
                </h6>
                <IndexButton event="" route="carteira-cliente" style-svg="" text="Carteira de Fornecedores">
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                    <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </IndexButton>
                <IndexButton event="" route="historico-fornecedores" style-svg="" text="Histórico dos Fornecedores">
                    <rect height="18" width="4" x="10" y="3"></rect>
                    <rect height="13" width="4" x="18" y="8"></rect>
                    <rect height="8" width="4" x="2" y="13"></rect>
                </IndexButton>
                <IndexButton event="" route="pedidos-multas" style-svg="" text="Pedidos e Multas">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </IndexButton>
                <IndexButton event="" route="envio-de-emails" style-svg="" text="Envio de E-mails">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                </IndexButton>
                <hr>
            </b-col>

<!--            <b-col class="menu-col">-->
<!--                <h6 class="menu-title">-->
<!--                    <span>Follow-Up</span>-->
<!--                </h6>-->
<!--                <IndexButton event=""-->
<!--                             route="follow-up-pedidos"-->
<!--                             style-svg="" text="Pedidos">-->
<!--                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>-->
<!--                    <rect height="4" rx="1" ry="1" width="8" x="8" y="2"></rect>-->
<!--                </IndexButton>-->
<!--                <IndexButton event="" route="follow-up-multas" style-svg="" text="Multas">-->
<!--                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>-->
<!--                    <line x1="4" x2="4" y1="22" y2="15"></line>-->
<!--                </IndexButton>-->
<!--                <hr>-->
<!--            </b-col>-->

            <b-col class="menu-col">
                <h6 class="menu-title">
                    <span>Sistema</span>
                </h6>

                <div v-if="getPermission()">
                    <IndexButton event="" route="cadastro-usuario" style-svg="" text="Usuários">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87">
                        </path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </IndexButton>

                </div>
                <index-button event="" route="alterar-senha" style-svg="" text="Alterar senha" >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87">
                    </path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </index-button>
                <IndexButton event="login-redirect" :route="login" style-svg="" text="Sair" v-on:login-redirect="loginRedirect">
                    <path d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5"></path>
                    <polyline points="17 16 21 12 17 8"></polyline>
                    <line x1="21" x2="9" y1="12" y2="12"></line>
                </IndexButton>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import IndexButton from "@/components/IndexButton";
    // import router from "@/router";

    export default {
        name: "IndexSidebar",
        components: {
            IndexButton
        },
        props: {
            widthImg: String,
        },
        data: function () {
            return {
                fixedMenu: 'fixed-menu',
                fixedText: 'Fixar Menu',
                rotateSvg: '',
                rotateClick: false,
                login: ''
            }
        },
        methods: {
            getPermission() {
                return localStorage.user_permission < 3
            },
            fixedChange() {
                this.rotateClick = !this.rotateClick;
                if (this.rotateClick) {
                    this.rotateSvg = 'transform: rotate(180deg);';
                    this.fixedText = 'Desafixar Menu';
                } else {
                    this.rotateSvg = 'transform: rotate(0deg);';
                    this.fixedText = 'Fixar Menu';
                }
                this.$emit(this.fixedMenu)
            },
            loginRedirect() {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_permission');
                localStorage.removeItem('user_name');
                localStorage.removeItem('user_id');
                this.login = "/login"
            }
        }
    }
</script>

<style scoped>
    img {
        width: 100%;
        padding: .5em .2em .2em .2em;
        transition: width 1s;
    }

    hr {
        margin: .5em 0 0 0;
        background-color: #dee2e6;
        height: 1px;
        width: 100%;
        transition: font-size 1s;
    }

    .header-image {
        height: 13em;
    }

    .menu-row {
        /*margin-top: 50vh;*/
        /*transform: translate(0, -50%);*/
    }

    .menu-col {
        padding-top: 1.5em;
    }

    .menu-title {
        text-align: center;
        font-size: 14px;
        color: #999999;
        text-transform: uppercase;
        /*margin-left: 1em;*/
    }

    .svg-icon {
    width: 1em;
    height: 1em;
    }

    .svg-icon path,
    .svg-icon polygon,
    .svg-icon rect {
    fill: #4691f6;
    }

    .svg-icon circle {
    stroke: #4691f6;
    stroke-width: 1;
    }
</style>
