<template>
    <div>
        <b-modal
                :id="id"
                @hidden="resetModal"
                @ok="handleOk"
                @show="resetModal"
                cancelTitle='Cancelar'
                cancelVariant="outline-secondary"
                centered
                header-bg-variant="success"
                header-text-variant="light"
                okTitle="Salvar"
                okVariant="success"
                ref="modal"
                size="xl"
                title="Novo Destinatário"
        >
            <form @submit.stop.prevent="handleSubmit" ref="form">
                <b-form-group
                        :state="nameState"
                        label="Insira o nome completo do novo destinatário:"
                        label-for="nome"
                >
                    <b-form-input
                            :state="nameState"
                            type="text"
                            id="nome"
                            required
                            v-model="nome"
                            placeholder="Nome e Sobrenome"
                    />
                </b-form-group>

                <b-form-group
                        :state="emailState"
                        label="Insira o email do novo destinatário:"
                        label-for="email"
                >
                    <b-form-input
                            :state="emailState"
                            type="email"
                            id="email"
                            required
                            v-model="email"
                            placeholder="email@email.com"
                    />
                </b-form-group>

                <b-form-group
                        :state="descricaoState"
                        label="Insira uma descrição para o novo destinatário:"
                        label-for="descricao"
                >
                    <b-form-input
                            :state="descricaoState"
                            type="text"
                            id="descricao"
                            required
                            v-model="descricao"
                            placeholder="Descrição"
                    />
                </b-form-group>
            </form>
            <p>{{ messageAlert }}</p>
        </b-modal>
    </div>
</template>

<script>
    import axios from "@/axios";

    export default {
        name: "DestinatarioNovo",
        components: {
        },
        props: {
            id: String,
        },
        data() {
            return {
                nome: '',
                email: '',
                descricao: '',
                messageAlert: '',
                access_token: '',
            }
        },
        computed: {
            nameState() {
                return this.nome.length >= 5
            },
            descricaoState() {
                return this.descricao != ''
            },
            isMail() {
                let is = false;
                for (let i = 0; i < this.email.length; i++) {
                    if (this.email.charAt(i) === '@' && i !== this.email.length - 1) {
                        return true;
                    }
                }
                return is;
            },
            emailState() {
                return this.email.length >= 5 && this.isMail
            },
        },
        methods: {
            tagChange(event) {
                this.users = event;
            },
            resetModal() {
                this.nome = '';
                this.email = '';
                this.descricao = '';
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.handleSubmit()
            },
            handleSubmit() {
                let self = this;
                if (!self.nameState || !self.emailState) {
                    self.messageAlert = 'Verifique se todos os campos foram preenchidos corretamente!';
                    return
                }
                let postSelected = {};
                postSelected.nome = this.nome;
                postSelected.email = this.email;
                postSelected.descricao = this.descricao;
                postSelected.users = this.users;
                this.access_token = "Bearer " + localStorage.access_token;
                axios.post('/email_relatorio', postSelected, {
                    headers: {
                        "Authorization": self.access_token
                    }
                })
                    .then(function () {
                        self.$nextTick(() => {
                            self.$bvModal.hide(self.id);
                            self.$emit("refresh-table");
                        })
                    })
                    .catch(function (error) {
                        alert(error)
                    });
            }
        }
    }
</script>

<style scoped>
    input:focus {
        /*border-color: #28a745 !important;*/
        box-shadow: none !important;
    }
    p {
        font-size: 13px;
        color: #dc3545;
    }
</style>
