<template>
    <div id="CadastrarUsuario">
        <BodyTitle title="Usuários"></BodyTitle>

        <FormButton text="Cadastrar Novo Usuário" event="new-user" v-on:new-user="newUser" v-if="deletePermission()">
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
        </FormButton>
        <UsuarioNovo :id="usuarioNew" v-on:refresh-table="refreshTable()"></UsuarioNovo>

        <UserTable :refresh-table="tableKey"></UserTable>
    </div>
</template>

<script>
    import BodyTitle from "@/components/BodyTitle";
    import UserTable from "@/components/UserTable";
    import FormButton from "@/components/FormButton";
    import UsuarioNovo from "@/modal/UsuarioNovo";

    export default {
        name: "CadastrarUsuario",
        components: {
            UsuarioNovo,
            FormButton,
            UserTable,
            BodyTitle
        },
        data() {
            return {
                usuarioNew: "usuarioNovo",
                tableKey: 0,
            }
        },
        methods: {
            newUser() {
                this.$bvModal.show(this.usuarioNew);
            },
            refreshTable() {
                this.tableKey++;
            },
            deletePermission() {
                return localStorage.user_permission < 2
            },
        }
    }
</script>

<style scoped>
    new-user {
        margin: 3em;
    }
</style>
