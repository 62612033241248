<template>
   <b-form-group class="on-print">
      <div class="row" v-if="hasDateFilter">
         <div class="col-sm-6">
            <label>Início do Período:</label>
            <BodyInput event="inicio-change" placeholder="" type="date"
                       v-on:inicio-change="inicioChange($event)">
               <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <rect height="18" rx="2" ry="2" width="18" x="3" y="4"></rect>
                  <line x1="16" x2="16" y1="2" y2="6"></line>
                  <line x1="8" x2="8" y1="2" y2="6"></line>
                  <line x1="3" x2="21" y1="10" y2="10"></line>
               </svg>
            </BodyInput>
         </div>
         <div class="col-sm-6">
            <label>Fim do Período:</label>
            <BodyInput event="fim-change" placeholder="" type="date" v-on:fim-change="fimChange($event)">
               <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <rect height="18" rx="2" ry="2" width="18" x="3" y="4"></rect>
                  <line x1="16" x2="16" y1="2" y2="6"></line>
                  <line x1="8" x2="8" y1="2" y2="6"></line>
                  <line x1="3" x2="21" y1="10" y2="10"></line>
               </svg>
            </BodyInput>
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12">
            <b-input-group class="input-box col-12 px-0" v-if="removeFornecedor">
               <template v-slot:prepend>
                  <b-input-group-text class="input-prepend">
                     <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                     </svg>
                  </b-input-group-text>
               </template>
               <b-form-input
                   class="input-filter"
                   id="filterInput"
                   placeholder="Pedido"
                   type="search"
                   v-model="filterPedido"
               ></b-form-input>
               <template v-slot:append>
                  <b-form-checkbox-group
                      v-if="disableCheckFilters ? false : true"
                      v-model="filterCheck"
                      buttons
                      button-variant="outline-success"
                      :options="options"
                  ></b-form-checkbox-group>
               </template>
            </b-input-group>
            <b-form inline v-else>
               <b-input-group class="input-box col-12 col-md-2 pl-0">
                  <template v-slot:prepend>
                     <b-input-group-text class="input-prepend">
                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                           <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                     </b-input-group-text>
                  </template>
                  <b-form-input
                      class="input-filter"
                      id="filterPedido"
                      placeholder="Pedido"
                      type="search"
                      v-model="filterPedido"
                  ></b-form-input>
               </b-input-group>

               <b-input-group class="input-box col-12 col-md-10 pr-0" v-if="!removeFornecedor">
                  <template v-slot:prepend>
                     <b-input-group-text class="input-prepend">
                        <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                           <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                     </b-input-group-text>
                  </template>
                  <b-form-input
                      class="input-filter"
                      id="filterInput"
                      placeholder="Fornecedor"
                      type="search"
                      v-model="filterInput"
                  ></b-form-input>
                  <template v-slot:append>
                     <b-form-checkbox-group
                         v-if="disableCheckFilters ? false : true"
                         v-model="filterCheck"
                         buttons
                         button-variant="outline-success"
                         :options="options"
                     ></b-form-checkbox-group>
                  </template>
               </b-input-group>
            </b-form>
         </div>
      </div>
      <b-input-group>
         <b-btn class="w-100 mt-2"
                v-on:click="$emit('filter-check-change', {status:filterCheck, text: filterInput, pedido: filterPedido})"
                variant="success">Pesquisar
         </b-btn>
      </b-input-group>
   </b-form-group>
</template>

<script>
import axios from "@/axios";
import BodyInput from "@/components/BodyInput";

export default {
   name: "FollowUpFilter",
   components: {
      BodyInput
   },
   props: {
      disableCheckFilters: Boolean,
      removeFornecedor: Boolean,
      hasDateFilter: Boolean,
   },
   data: function () {
      return {
         access_token: '',
         filterInput: '',
         filterPedido: '',
         filterCheck: [],
         options: []
      }
   },
   methods: {
      inicioChange(event) {
         this.$emit("date-ini-change", {dateIni: event});
      },
      fimChange(event) {
         this.$emit("date-fim-change", {dateFim: event});
      },
   },
   watch: {
      filterPedido: function (new_val, old_val) {
         this.$emit("pedido-change", {pedido: this.filterPedido});
      },
      filterInput: function (new_val, old_val) {
         //fornecedor
         this.$emit("fornecedor-change", {fornecedor: this.filterInput});
      },
      filterCheck: function (new_val, old_val) {
         //fornecedor
         this.$emit("status-change", {status: this.filterCheck});
      }
   },
   mounted() {
      this.access_token = "Bearer " + localStorage.access_token;

      let self = this;
      axios.get('/get_status_pedido', {
         headers: {
            "Authorization": self.access_token
         }
      })
          .then(function (response) {
             response.data.forEach(status => {
                self.options.push({
                   text: status, value: status
                });
             });
          })
          .catch(function (error) {
             // eslint-disable-next-line no-console
             console.log(error);
          })
   }
}
</script>

<style scoped>
svg {
   width: 1.5em;
   height: 1.5em;
   fill: none;
   stroke: currentColor;
   stroke-width: 2px;
   stroke-linecap: round;
   stroke-linejoin: round;
}

.input-prepend {
   color: #ffffff;
   background-color: #28a745;
   border-color: #28a745;
}

.input-filter {
   border-color: #dee2e6;
   color: #212529;
}

.input-filter:focus {
   border-color: #28a745;
   box-shadow: none;
}

@media print {
   .on-print {
      display: none;
   }
}
</style>
