import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'axios';
import router from './router.js';
import '@/plugins/apexcharts'

import BootstrapVue from 'bootstrap-vue'
import App from "@/App";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');


router.beforeEach((to, from, next) => {
  if(to.name !== 'login'){
    if(localStorage.access_token !== undefined && localStorage.access_token !== null){
      next();
    }else{
      next('/login');
    }
  }
  next()
});
