<template>
    <div id="PedidosMultas">
        <BodyTitle title="Pedidos e Multas"></BodyTitle>

        <FilterMultasPedidos class="on-print" event="filter-change" v-on:filter-change="filterChange($event)"></FilterMultasPedidos>

        <div class="on-print-padding" v-if="pedidosCollection.datasets.length > 0 || multasCollection.datasets.length > 0">
            <div class="mb-5">
                <ChartLine :key="pedidosKey" v-bind:datacollection="{
                labels: this.pedidosCollection.label,
                datasets: [
                    {
                        label: ' Valor de Pedidos',
                        fill: false,
                        pointBackgroundColor: 'white',
                        borderWidth: 4,
                        borderColor: '#13d1f8',
                        pointBorderColor: '#13d1f8',
                        data: this.pedidosCollection.datasets
                    },
                ]
                }"/>
            </div>
            <div class="mb-5">
                <ChartLine :key="multasKey" v-bind:datacollection="{
                labels: this.multasCollection.label,
                datasets: [
                    {
                        label: ' Valor de Multas',
                        fill: false,
                        pointBackgroundColor: 'white',
                        borderWidth: 4,
                        borderColor: '#e91e63',
                        pointBorderColor: '#e91e63',
                        data: this.multasCollection.datasets
                    },
                ]
                }"/>
            </div>
        </div>
        <p v-if="(multasCollection.datasets.length === 0 || pedidosCollection.datasets.length === 0) && !loading && filter !== null" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>

    </div>
</template>

<script>
    import ChartLine from "@/components/ChartLine";
    import BodyTitle from "@/components/BodyTitle";
    import FilterMultasPedidos from "@/components/FilterMultasPedidos";
    import axios from "@/axios";

    export default {
        name: "PedidosMultas",
        components: {
            FilterMultasPedidos,
            BodyTitle,
            ChartLine
        },
        data() {
            return {
                pedidosKey: 0,
                multasKey: 0,
                filter: null,
                pedidosCollection:{
                    label:[],
                    datasets: []
                },
                multasCollection:{
                    label:[],
                    datasets: []
                },
                loading: false,
                access_token: "Bearer " + localStorage.access_token,
            }
        },
        methods: {
            filterChange(event) {
                this.filter = event;
                this.search();
            },
            search(){
                this.loading = true;
                let filter = {
                    fornecedor: this.filter.fornecedor || null,
                    dataPedidoBegin: this.filter.inicio || null,
                    dataPedidoEnd: this.filter.fim || null,
                };
                let self = this;
                this.pedidosCollection.label = [];
                this.pedidosCollection.datasets = [];
                this.multasCollection.label = [];
                this.multasCollection.datasets = [];
                axios.get('/report/pedidos_multas', {
                    params: filter,
                    headers: {
                        "Authorization": this.access_token
                    }
                })
                    .then(function (response) {
                        Object.keys(response.data.data).forEach(item => {
                            self.pedidosCollection.label.push(item);
                            self.multasCollection.label.push(item);
                            self.pedidosCollection.datasets.push(response.data.data[item].valor_pedidos);
                            self.multasCollection.datasets.push(response.data.data[item].valor_multas);
                        });
                        self.pedidosKey++;
                        self.multasKey++;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        self.pedidosKey++;
                        self.multasKey++;
                        self.loading = false;
                    });
            }
        },
    }
</script>

<style scoped>
    @media print {
        .on-print {
            display: none;
        }
        .on-print-padding {
            padding-top: 5em;
        }
    }
</style>
