<template>
    <div id="HistoricoMultas">
        <BodyTitle @exportExcel="handleExcel" :export-excel="true" title="Histórico de Multas"></BodyTitle>

        <FollowUpTable
                @search-with-filter="search(1,$event)"
                :enable-class="true"
                :hasDateFilter="true"
                :items="itemsCollection"
                :basic-fields="basicFields"
                :item-fields="itemFields"
                v-on:pedido-change="handleChangeProp($event)"
                v-on:fornecedor-change="handleChangeProp($event)"
                v-on:status-change="handleChangeProp($event)"
                v-on:date-ini-change="handleChangeProp($event)"
                v-on:date-fim-change="handleChangeProp($event)"
        ></FollowUpTable>
        <b-pagination
                class="on-print"
                v-show="!loading && itemsCollection.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="fieldTable"
                @input="search"
        ></b-pagination>

        <p v-if="itemsCollection.length === 0 && !loading" class="msg-length">Nenhuma informação encontrada.</p>
        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>
    </div>
</template>

<script>
    import FollowUpTable from "@/components/FollowUpTable";
    import BodyTitle from "@/components/BodyTitle";
    import axios from "@/axios";
    import {format} from 'date-fns'

    export default {
        name: "HistoricoMultas",
        components: {
            BodyTitle,
            FollowUpTable,
        },
        data: function () {
            return {
                url: "/report/historico_multas",
                access_token: '',
                itemsCollection: [],
                loading: false,
                currentPage: 1,
                perPage: 3,
                totalRows: 0,
                filters: {
                    page: 1,
                    fornecedor: null,
                    pedido: null,
                   dateIni:'2021-05-12 00:00:00',
                },
               basicFields:[
                  {key: 'fornecedor', sortable: true,},
                  {key: 'pedido', sortable: true},
                  {key: 'item', sortable: true},
                  {key: 'unidadeKlabin', sortable: true},
                  {key: 'numNF', sortable: false},
                  {key: 'quantidade', sortable: true, label: 'Qte faturada'},
                  {key: 'dataEmissaoNF', sortable: false},
                  {key: 'dataEntrega', sortable: true},
                  {key: 'dataLancamento', sortable: false},
                  {key: 'valorMulta', sortable: true},
                  {key: 'diasAtraso', sortable: true},
                  {key: 'detalhes', sortable: false}
               ],
               itemFields: [
                  {key: 'codMaterial', sortable: false},
                  {key: 'texto', sortable: false},
                  {key: 'quantidade', sortable: false},
                  {key: 'valorUnitario', label: 'Valor Total',sortable: false},
                  {key: 'multa', label: 'Multa Diária', sortable: false},
               ]
            };
        },
        methods:{
            money(value){
                let val = (value).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            dateTimeToDate(value){
                if(value !== null){
                    return format(new Date(value), 'dd/MM/yyyy');
                }
                return null;
            },
            handleChangeProp(event){
                if(event.pedido != null){
                    this.filters.pedido = event.pedido;
                }else if(event.fornecedor != null){
                    this.filters.fornecedor = event.fornecedor;
                }else if(event.dateIni != null){
                    this.filters.dateIni = event.dateIni;
                }else if(event.dateFim != null){
                    this.filters.dateFim = event.dateFim;
                }else
                    console.log("ok", event);
            },
            handleExcel() {
                const self = this;
                let filters = this.filters;
                filters.page = 1;
                filters.perPage = false;
                filters.pedido = filters.pedido === '' ? null : filters.pedido;
                filters.fornecedor = filters.fornecedor === '' ? null : filters.fornecedor;
                axios.get(self.url, {
                    headers: {
                        "Authorization": self.access_token,
                        "Accept": "application/xls"
                    },
                    params: filters,
                }).then(function (response) {
                    window.open(response.data.data.path);
                });
            },
            search(page, filter = {}){
                this.filters.page = page;
                if(filter !== null){
                    this.filters.fornecedor = filter.text ? filter.text : null;
                    this.filters.pedido = filter.pedido && filter.pedido !== '' ? filter.pedido : null;
                }
                const self = this;
                self.loading = true;
                self.itemsCollection = [];
                axios.get(self.url, {
                    headers: {
                        "Authorization": self.access_token
                    },
                    params: this.filters,
                })
                    .then(function (response) {
                        self.currentPage = response.data.meta.current_page;
                        self.perPage = response.data.meta.per_page;
                        self.totalRows = response.data.meta.total;
                        self.loading = false;
                        self.itemsCollection = response.data.data;

                        self.itemsCollection.forEach( function (element) {
                            if (element.dataPedido != null) {
                                element.dataPedido = self.dateTimeToDate(element.dataPedido);
                            }
                            if (element.dataEntrega != null) {
                                element.dataEntrega = self.dateTimeToDate(element.dataEntrega);
                            }
                            if (element.dataCriacao != null) {
                                element.dataCriacao = self.dateTimeToDate(element.dataCriacao);
                            }
                            if (element.dataEmissaoNF != null) {
                                element.dataEmissaoNF = self.dateTimeToDate(element.dataEmissaoNF);
                            }
                            if (element.valorMulta != null) {
                                element.valorMulta = self.money(element.valorMulta);
                            }
                            if (element.valorUnitario != null) {
                                element.valorUnitario = self.money(element.valorUnitario);
                            }
                        });
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // eslint-disable-next-line no-console
                        console.log(error);
                    })
            }
        },
        mounted: function () {
            this.access_token = "Bearer " + localStorage.access_token;
            this.search(1);
        }
    }
</script>

<style scoped>
    #Pedidos {
        font-size: 14px;
    }
</style>
