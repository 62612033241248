<template>
    <div id="FilterEventosEmails">
        <div class="card">
            <label>Nome do Fornecedor:</label>
            <BodyTag :options="options" event="tag-change" v-on:tag-change="tagChange($event)">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" data-v-14b21170="" data-v-3264a8d8=""></path>
                <circle cx="12" cy="7" data-v-14b21170="" data-v-3264a8d8="" r="4"></circle>
            </BodyTag>
            <div class="row">
                <div class="col-sm">
                    <label>Início do Período:</label>
                    <BodyInput event="inicio-change" placeholder="" type="date"
                               v-on:inicio-change="inicioChange($event)">
                        <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect height="18" rx="2" ry="2" width="18" x="3" y="4"></rect>
                            <line x1="16" x2="16" y1="2" y2="6"></line>
                            <line x1="8" x2="8" y1="2" y2="6"></line>
                            <line x1="3" x2="21" y1="10" y2="10"></line>
                        </svg>
                    </BodyInput>
                </div>
                <div class="col-sm">
                    <label>Fim do Período:</label>
                    <BodyInput event="fim-change" placeholder="" type="date" v-on:fim-change="fimChange($event)">
                        <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect height="18" rx="2" ry="2" width="18" x="3" y="4"></rect>
                            <line x1="16" x2="16" y1="2" y2="6"></line>
                            <line x1="8" x2="8" y1="2" y2="6"></line>
                            <line x1="3" x2="21" y1="10" y2="10"></line>
                        </svg>
                    </BodyInput>
                </div>
                <div class="col-sm">
                    <label>Status:</label>
                    <BodySelect event="status-change" placeholder="" options="statusList" v-on:status-change="statusChange($event)">
                        <svg class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                <line x1="3" y1="18" x2="3.01" y2="18"></line>
                            </svg>
                        </svg>
                    </BodySelect>
                </div>
            </div>
            <b-btn v-on:click="buttonSubmit" variant="success">Pesquisar</b-btn>
        </div>
    </div>
</template>

<script>
    import BodyInput from "@/components/BodyInput";
    import BodyTag from "@/components/BodyTag";
    import BodySelect from "@/components/BodySelectStatus";

    export default {
        name: "FilterEventosEmails",
        components: {
            BodyTag,
            BodyInput,
            BodySelect,
        },
        props: {
          event: String
        },
        data: function () {
            return {
                filter: {
                    fornecedor: [],
                    data_inicial: '',
                    data_final: '',
                    status: '',
                },
                options: [],
            }
        },
        methods: {
            tagChange(event) {
                this.filter.fornecedor = event;
            },
            inicioChange(event) {
                this.filter.data_inicial = event + ' 00:00:00';
            },
            fimChange(event) {
                this.filter.data_final = event + ' 23:59:59';
            },
            statusChange(event) {
                this.filter.status = event;
            },
            buttonSubmit() {
                this.$emit(this.event, this.filter);
            }
        }
    }
</script>

<style scoped>
    label {
        color: #999;
    }

    .card {
        border-color: #28a745;
        padding: 1em;
        margin-bottom: 4em;
    }

    .btn-success:hover {
        border-color: #28a745;
        background: none;
        color: #28a745;
    }
</style>
