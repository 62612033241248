<template>
    <b-form-group class="on-print">
        <b-input-group>
            <template v-slot:prepend>
                <b-input-group-text class="input-prepend">
                    <svg class="feather" style="margin-right: 0" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                    </svg>
                </b-input-group-text>
            </template>
            <b-form-input
                    class="input-filter"
                    id="filterInput"
                    placeholder="Nome do usuário"
                    type="search"
                    v-model="filterInput"
                    @keyup.enter="$emit('filter-check-change', {text: filterInput})"
            ></b-form-input>
            <b-btn @click="$emit('filter-check-change', {text: filterInput})" variant="success">Pesquisar</b-btn>
        </b-input-group>
    </b-form-group>
</template>

<script>
    export default {
        name: "UserFilter",
        data: function () {
            return {
                filterInput: ''
            }
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .input-prepend {
        color: #ffffff;
        background-color: #28a745;
        border-color: #28a745;
    }

    .input-filter {
        border-color: #dee2e6;
        color: #212529;
    }

    .input-filter:focus {
        border-color: #28a745;
        box-shadow: none;
    }

    @media print{
        .on-print {
            display: none;
        }
    }
</style>
