<template>
   <div>
      <b-modal
          :id="id"
          @hidden="resetModal"
          @ok="handleOk"
          @show="resetModal"
          cancelTitle='Cancelar'
          cancelVariant="outline-secondary"
          centered
          header-bg-variant="success"
          header-text-variant="light"
          okTitle="Salvar"
          okVariant="success"
          ref="modal"
          size="xl"
          title="Detalhes do Pedido"
      >

         <b-table :fields="fields" :items="selected" class="table-padding" hover responsive="sm" small></b-table>
         <form @submit.stop.prevent="handleSubmit" ref="form">
            <b-form-group
                :state="state"
                label="Contestação sobre os valores da multa ou observações sobre o item:"
                label-for="observacoes"
            >
               <b-form-input
                   :state="state"
                   id="observacoes"
                   placeholder="Contestação/ Observações"
                   required
                   v-model="observacao"
               />
               <label v-if="selected[0].observacao != null && selected[0].observacao !== ''"><b>Registro atual:</b> {{selected[0].observacao}}</label>
            </b-form-group>
         </form>
      </b-modal>
   </div>
</template>

<script>
import axios from "@/axios";

export default {
   name: "DetalhesMulta",
   props: {
      selected: Array,
      id: String,
   },
   data() {
      return {
         observacao: '',
         fields: [
            {key: 'pedido', sortable: false},
            {key: 'dataPedido', sortable: false},
            {key: 'dataEntrega', sortable: false},
            {key: 'diasAtraso', sortable: false},
            {key: 'item', sortable: false},
            {key: 'codMaterial', sortable: false},
            {key: 'valorMulta', sortable: false},
         ],
      }
   },
   computed: {
      state() {
         return this.observacao.length >= 1
      }
   },
   methods: {
      resetModal() {
         this.observacao = '';
      },
      handleOk(bvModalEvt) {
         bvModalEvt.preventDefault();
         this.handleSubmit()
      },
      handleSubmit() {
         if (this.observacao.length < 1) {
            return
         }
         let postSelected = this.selected[0];
         postSelected.observacao = this.observacao;
         postSelected.hash = this.$route.params.hash;

         let self = this;
         axios.post('/gravar-dados/multas', postSelected)
             .then(function () {
                self.$nextTick(() => {
                   self.$bvModal.hide(self.id);
                   self.$emit('table-update');
                })
             })
             .catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
             });
      }
   }
}
</script>

<style scoped>
.table-padding {
   padding-bottom: 1em;
}

input:focus {
   /*border-color: #28a745 !important;*/
   box-shadow: none !important;
}
</style>
