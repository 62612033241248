<template>
    <div id="ButtonTable">
        <div @click="isActive = !isActive" class="input-group mb-3">
            <div class="input-group-prepend">
                <!--                <span class="input-group-text" v-bind:class="[isActive ? 'red' : 'green']">-->
                <span :class="status" class="input-group-text">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z"></path>
                    </svg>
                </span>
            </div>
            <span class="form-control">{{ text }}</span>
            <div class="input-group-append">
                <span class="input-group-text">
                    <svg v-bind:class="[isActive ? 'down' : 'up']" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
              </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ButtonTable",
        props: {
            text: String,
            status: String
        },
        data() {
            return {
                isActive: true
            }
        }
    }
</script>


<style scoped>
    #ButtonTable {
        padding: 0;
        margin: 0;
        color: #212529;
    }

    #ButtonTable:hover {
        cursor: pointer;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .form-control:hover {
        background-color: #e9ecef;
    }

    .danger {
        background-color: #dc3545;
        border-color: #dc3545;
        color: white;
    }

    .success {
        background-color: #28a745;
        border-color: #28a745;
        color: white;
    }

    .warning {
        background-color: #ffc107;
        border-color: #ffc107;
        color: white;
    }

    .down {
        transform: rotate(270deg);
    }

    .up {
        transform: rotate(0deg);
    }

</style>
