import {format} from "date-fns";
<template>
    <div id="FollowUpTable">

        <FilterFollowUp :removeFornecedor="removeFornecedor"
                        :disableCheckFilters="disableCheckFilters"
                        :hasDateFilter="hasDateFilter"
                        v-on:filter-input-change="filter = $event"
                        v-on:filter-check-change="filterCheck($event)"
                        v-on:pedido-change="$emit('pedido-change', $event)"
                        v-on:fornecedor-change="$emit('fornecedor-change', $event)"
                        v-on:status-change="$emit('status-change', $event)"
                        v-on:date-ini-change="$emit('date-ini-change', $event)"
                        v-on:date-fim-change="$emit('date-fim-change', $event)"
        />

        <b-table
                :fields="fields"
                :filter="filter"
                :items="checkedItems"
                :tbody-tr-class="rowClass"
                @row-clicked="onRowSelected"
                hover
                no-border-collapse
                ref="selectableTable"
                responsive="lg"
                striped
        >
            <template v-slot:cell(detalhes)="row">
                <b-button @click="row.toggleDetails" size="md" v-bind:class="buttonClass(row.item)">
                    <div v-if="row.detailsShowing">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="10 15 15 20 20 15"></polyline>
                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </div>
                    <div v-else>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="15 10 20 15 15 20"></polyline>
                            <path d="M4 4v7a4 4 0 0 0 4 4h12"></path>
                        </svg>
                    </div>
                </b-button>
            </template>

            <template v-slot:row-details="row">
                <b-card v-bind:class="cardClass(row.item)">
                    <div class="table-padding">
                        <h4 class="table-title">Detalhes</h4>
                        <b-table :fields="pedidoDetailsFields" :items="[row.item]" fixed responsive="lg" small
                                 table-variant="light"></b-table>
                    </div>

                    <div class="table-padding">
                        <h4 class="table-title">Item</h4>
                        <b-table :fields="itemDetailsFields" :items="[row.item]" fixed responsive="lg"
                                 small table-variant="light">
                            <template v-slot:cell(multa)="row">
                                {{ row.item.multa * 100 + '%' }}
                            </template>
                        </b-table>
                    </div>
                    <div class="table-padding"
                         v-if="
                            row.item.status !== undefined
                            && (row.item.status.toUpperCase() === 'FATURADO'
                                || row.item.status.toUpperCase() === 'PREVISÃO')"
                    >
                        <h4 class="table-title"
                            v-if="row.item.status !== undefined && row.item.status.toUpperCase() === 'FATURADO'">Nota
                            Fiscal</h4>
                        <h4 class="table-title"
                            v-if="row.item.status !== undefined && row.item.status.toUpperCase() === 'PREVISÃO'">
                            Previsão de Entrega</h4>
                        <b-table :fields="nfDetailsFields" :items="[row.item]" fixed responsive="lg"
                                 small table-variant="light"
                                 v-if="row.item.status !== undefined && row.item.status.toUpperCase() === 'FATURADO'">
                            <template v-slot:cell(download)="row">
                                <b-button @click="downloadNfe(row)" size="md" class="btn-table-inner-download">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 17v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3"></path>
                                        <polyline points="8 12 12 16 16 12"></polyline>
                                        <line x1="12" y1="2" x2="12" y2="16"></line>
                                    </svg>
                                </b-button>
                            </template>
                        </b-table>
                        <b-table :fields="previsaoDetailsFields" :items="[row.item]" fixed responsive="lg"
                                 small table-variant="light"
                                 v-if="row.item.status !== undefined && row.item.status.toUpperCase() === 'PREVISÃO'"></b-table>
                    </div>

                    <b-button @click="row.toggleDetails" class="w-100 on-print" size="sm"
                              v-bind:class="buttonClass(row.item)">
                        Ocultar Detalhes
                    </b-button>
                </b-card>
            </template>
        </b-table>
    </div>
</template>

<script>
    import FilterFollowUp from "@/components/FollowUpFilter";
    import axios from "@/axios";

    export default {
        name: "FollowUpTable",
        components: {
            FilterFollowUp
        },
        props: {
            modalId: String,
            items: Array,
            basicFields: Array,
            itemFields: Array,
            enableClass: Boolean,
            tableUpdate: Number,
            disableCheckFilters: Boolean,
            removeFornecedor: Boolean,
           hasDateFilter: Boolean,
        },
        data: function () {
            return {
                filter: '',
                checkedItems: [],
                selected: [],
                access_token: "Bearer " + localStorage.access_token,
                fields: this.basicFields != null ? this.basicFields : [
                    {key: 'statusPortalMe', sortable: true},
                    {key: 'fornecedor', sortable: true,},
                    {key: 'pedido', sortable: true},
                    {key: 'item', sortable: true,},
                    {key: 'quantidade', sortable: true,},
                    {key: 'saldo', sortable: true,},
                    {key: 'unidadeKlabin', sortable: true,},
                    {key: 'dataEntrega', sortable: true,},
                    {key: 'valorMulta', sortable: true},
                    {key: 'diasAtraso', sortable: true},
                    {key: 'detalhes', sortable: false},
                ],
                pedidoDetailsFields: [
                    {key: 'dataPedido', sortable: true,},
                    {key: 'codFornecedor', sortable: false},
                    {key: 'comprador', sortable: true,},
                    {key: 'incoterms', sortable: false},
                    {key: 'unidadeCompra', sortable: false},
                ],
                itemDetailsFields: this.itemFields != null ? this.itemFields : [
                    {key: 'codMaterial', sortable: false},
                    {key: 'texto', sortable: false},
                    {key: 'quantidade', sortable: false},
                    {key: 'valorUnitario', sortable: false},
                    {key: 'multa', label: 'Multa Diária', sortable: false},
                ],
                nfDetailsFields: [
                    {key: 'numNF', sortable: false},
                    {key: 'dataEmissao', sortable: false},
                    {key: 'qtdFaturada', sortable: false},
                    {key: 'download', label: 'Baixar NFe', sortable: false}
                ],
                // nfMultasDetailsFields: [
                //     {key: 'numNF', sortable: false},
                //     {key: 'dataEmissaoNF', sortable: false},
                //     {key: 'quantidade', sortable: false},
                //     {key: 'dataLancamento', sortable: false}
                // ],
                previsaoDetailsFields: [
                    {key: 'observacoes', label: 'Observações', sortable: false},
                    {key: 'dataPrevisao', sortable: false},
                ],
            }
        },
        methods: {
            onRowSelected(items) {
                this.selected = [items];
                this.$emit('row-selected', this.selected);
                this.$bvModal.show(this.modalId);
            },
            rowClass(item) {
                if (!this.enableClass) return 'none';

                if (item.status !== undefined && item.status.toUpperCase() === 'FATURADO') return 'table-success';
                else if (item.status !== undefined && item.status.toUpperCase() === 'PREVISÃO') return 'table-warning';
                else if (item.observacao != null && item.observacao !== '') return 'table-success';
                else return 'none';
            },
            cardClass(item) {
                if (!this.enableClass) return 'card-border-sem';

                if (item.status !== undefined && item.status.toUpperCase() === 'FATURADO') return 'card-border-faturado';
                else if (item.status !== undefined && item.status.toUpperCase() === 'PREVISÃO') return 'card-border-previsao';
                else if (item.observacao != null && item.observacao !== '') return 'card-border-faturado';
                else return 'card-border-sem';
            },
            buttonClass(item) {
                if (!this.enableClass) return 'btn-table-inner-sem';

                if (item.status !== undefined && item.status.toUpperCase() === 'FATURADO') return 'btn-table-inner-faturado';
                else if (item.status !== undefined && item.status.toUpperCase() === 'PREVISÃO') return 'btn-table-inner-previsao';
                else if (item.observacao != null && item.observacao !== '') return 'btn-table-inner-faturado';
                else return 'btn-table-inner-sem';
            },
            filterCheck(event) {
                this.$emit('search-with-filter', event);
            },
            downloadNfe(row) {
                axios.get('/download', {
                    params: {'anexo': row.item.anexo},
                    headers: {
                        "Authorization": this.access_token
                    },
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        });
                        let url = window.URL.createObjectURL(blob);
                        window.open(url);

                    })
                    .catch(error => {
                        alert("Nenhuma nota fiscal foi adicionada a esse pedido.")
                    })
            }
        },
        watch: {
            items: function () {
                this.checkedItems = this.items;
                // if (this.checkedItems.length === 0 || this.checkedItems.length === 3) {
                //     this.checkedItems = this.items;
                // }
            }
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .input-prepend {
        color: #ffffff;
        background-color: #28a745;
        border-color: #28a745;
    }

    .input-filter {
        border-color: #dee2e6;
        color: #212529;
    }

    .input-filter:focus {
        border-color: #28a745;
        box-shadow: none;
    }

    .table-title {
        background-color: #dee2e6;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding: .25em;
        color: #212529;
        width: 100%;
        border-radius: .25em .25em 0 0;
        margin: 0;
    }

    .table-padding {
        padding-bottom: 1em;
    }

    .card-border-faturado {
        border-color: #28a745;
    }

    .card-border-previsao {
        border-color: #ffc107;
    }

    .card-border-sem {
        border-color: #6c757d;
    }

    .btn-table-inner-faturado {
        color: #ffffff;
        border-color: #28a745;
        background: #28a745;
    }

    .btn-table-inner-faturado:hover {
        color: #28a745;
        border-color: #28a745;
        background-color: #ffffff;
    }

    .btn-table-inner-previsao {
        color: #ffffff;
        border-color: #ffc107;
        background: #ffc107;
    }

    .btn-table-inner-previsao:hover {
        color: #ffc107;
        border-color: #ffc107;
        background-color: #ffffff;
    }

    .btn-table-inner-sem {
        color: #ffffff;
        border-color: #6c757d;
        background: #6c757d;
    }

    .btn-table-inner-sem:hover {
        color: #6c757d;
        border-color: #6c757d;
        background-color: #ffffff;
    }

    .btn-table-inner-download {
        color: #6c757d;
        border: none;
        background: none;
    }

    .btn-table-inner-download:hover {
        color: currentColor;
    }

    @media print {
        .on-print {
            display: none;
        }
    }
</style>
