<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "App"
    }
</script>

<style>
    .pagination .page-item.active a.page-link{
        background: #28a745 !important;
        border-color: #28a745 !important;
        color: #ffffff !important;
    }

    .pagination .page-item a.page-link{
        color: #28a745 !important;
    }
    .msg-length{
        text-align: center;
        width: 100%;
    }
    @media print{
        .on-print {
            display: none;
        }
    }

    *:focus{
        outline: none !important;
        border:0;
        box-shadow: 0 0 0 0 transparent;
    }

    input:focus{
        border: 1px solid #595959;
    }

</style>
