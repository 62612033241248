import axios from 'axios';
// import router from "@/router";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 60000 * 10,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
});

axios.interceptors.response.use(function (response) {
    return response;

}, function (error) {

    if (error.response.config.url !== '/login') {
        self.warning_message = "Não foi possível realizar a requisição. Tente novamente mais tarde!";

        // switch (error.response.status) {
        //     case 400 :
        //         alert('Você precisa estar logado para acessar este recurso');
        //         router.push({path: '/login'});
        //         break;
        //     case 401 :
        //         alert('Você precisa estar logado para acessar este recurso');
        //         router.push({path: '/login'});
        //         break;
        //     case 403 :
        //         alert('Você precisa estar verificado para acessar este recurso');
        //         break;
        //     case 422 :
        //         alert('O servidor recusou os dados informado, confira as informações');
        //         break;
        //     default :
        //         self.warning_message = "Não foi possível realizar a requisição. Tente novamente mais tarde!";
        //         break;
        // }
    }
    return Promise.reject(error);
});

export default instance;
