<template>
    <div>
        <BodyTitle @exportExcel="handleExcel" :exporting="exporting" :export-excel="true" title="Relatório de Envio de E-mails"></BodyTitle>

        <FilterEventosEmails class="on-print" event="filter-change" 
        v-on:filter-change="filterChange($event)"></FilterEventosEmails>

        <div v-show="!loading">
            <div>
                <p v-if="EmailsData.length === 0 &&
                !loading &&
                pesquisa && 
                filter !== null" 
                    class="msg-length">Nenhuma informação encontrada.
                </p>
                <p v-if="getError" class="msg-length">
                    Favor definir data inicial e data final.
                </p>
            </div>

            <div id="chart" class="ex1 on-print-padding" v-if="series.length > 0">
                <apexchart height="600" :options="chartDonut" :series="series"></apexchart>
                <!-- <apexchart type="donut" :options="chartOptions" :series="series"></apexchart> -->
            </div>

            <p></p>

            <div>
                <b-card class="card" title="TabelaEmails" no-body v-if="EmailsData.length > 0">

                    <b-card-body class="text-center">
                        <b-card-text>
                            <div>
                                <EventosEmailsTable
                                    @search-with-filter="search($event)"
                                    :enable-class="true"
                                    :perPage = "per_page"
                                    :items="EmailsData"
                                    :lastPage="last_page"
                                ></EventosEmailsTable>
                            </div>
                        </b-card-text>

                        <b-pagination 
                            class="on-print" 
                            v-if="true" 
                            align="center" 
                            v-model="currentPage"
                            :total-rows='per_page * last_page' 
                            :per-page="per_page">
                        </b-pagination>

                    </b-card-body>
                </b-card>
            </div>
        </div>

        <div class="col-12 d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" class="loading" label="Loading..."></b-spinner>
        </div>

    </div>
</template>

<script>
    import FilterEventosEmails from "@/components/FilterEventosEmails";
    import axios from "@/axios";
    import BodyTitle from "@/components/BodyTitle";
    import '@/plugins/apexcharts';
    import VueApexCharts from 'vue-apexcharts';
    import EventosEmailsTable from "@/components/EventosEmailsTable";

    export default {

        name: "Dashboard",
        components: {
            apexchart: VueApexCharts,
            FilterEventosEmails,
            BodyTitle,
            EventosEmailsTable,
        },
        data: () =>{
            return {
                pesquisa: false,
                tab: 6,
                total: [],
                tableSelector: 6,
                myLog: {},
                config: '',
                access_token: '',
                dialog: false,
                loading: false,
                exporting: false,
                getError: false,
                last_page: 1,
                per_page: 45,
                currentPage: 1,
                filter: {
                    fornecedor: [],
                    data_inicial: '',
                    data_final: '',
                    status:''
                },
                EmailsData: [],
                series: [],
                chartDonut: {
                    chart: {
                        type: 'donut',
                        events: {
                            dataPointSelection: (event, chartContext, config) => {
                                console.log(config.dataPointIndex)
                                var point = config.dataPointIndex
                                // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                            }
                        }
                    },
                    labels: ['Entregues', 'Acessados', 'Respondidos', 'Erros no Envio'],
                    colors: ['#26EEF5D8', '#163EF1', '#00e296', '#ff0000'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            plotOptions: {
                                pie: {
                                    expandOnClick: false,
                                    donut: {
                                        labels: {
                                            show: true,
                                            name: {
                                                show: false
                                            },
                                            value: {
                                                offsetY: -1,
                                                show: true
                                            },
                                            total: {
                                                show: true
                                            }
                                        }
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: (val) => {
                                    return val + "%"
                                },
                                style: {
                                    fontSize: '14px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 'bold',
                                    colors: undefined
                                },
                                dropShadow: {
                                    enabled: false,
                                    top: 1,
                                    left: 1,
                                    blur: 1,
                                    color: '#000',
                                    opacity: 0.45
                                }
                            },
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        },
                    }]
                },
                
                

            }
        },

        mounted: function () {     
    },

        watch: {
            currentPage() {
                this.search(this.currentPage)
            }    
        },

        methods: {

            selectedBar(){
                tableSelector = point
            },

            filterChange(event) {
                this.filter = event;
                this.search(1);
            },

            search(page) {
                this.loading = true
                this.filter = {
                    page: page,
                    per_page: this.per_page,
                    status: this.filter.status || null,
                    fornecedor: this.filter.fornecedor || null,
                    data_inicial: this.filter.data_inicial || null,
                    data_final: this.filter.data_final || null,
                };
                this.access_token = "Bearer " + localStorage.access_token;
                this.getGraphic()
                this.getMyLog()
            },

            getGraphic() {
                axios.get('/report/log/graphic', {
                    params: this.filter,
                    headers: {
                        "Authorization": this.access_token,
                    },
                })
                    .then(response => {
                        this.series = [response.data.ENTREGUE, response.data.ACESSADO, response.data.RESPONDIDO, response.data.ERRO_ENVIO]
                        console.log('series',this.series)
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.getError = true
                            this.loading = false
                            console.log(this.getError)
                        } else if (error.request) {

                        } else {
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    })
            },

            getMyLog(){
                axios.get('/report/log', {
                        params: this.filter,
                        headers: {
                            "Authorization": this.access_token,
                        },
                    })
                    .then(response =>{
                        this.myLog = response.data
                        this.last_page = this.myLog.meta.last_page
                        this.EmailsData = this.myLog.data
                        this.pesquisa=true
                        this.getError = false
                        this.loading = false
                    })
                    .catch((error) =>{
                        if (error.response) {
                            this.getError = true
                            this.loading = false
                            console.log(this.getError)
                        } else if (error.request) {
                            
                        } else {
                        console.log('Error', error.message);
                        }
                        console.log(error.config);
                    })
            },
            handleExcel() {
              const self = this;
              this.exporting = true;
              let filters = {
                page: 1,
                per_page: 45,
                status: this.filter.status || null,
                fornecedor: this.filter.fornecedor || null,
                data_inicial: this.filter.data_inicial || null,
                data_final: this.filter.data_final || null,
              };
              axios.get('/report/log', {
                headers: {
                  "Authorization": self.access_token,
                  "Accept": "application/xls"
                },
                params: filters,
              }).then(function (response) {
                self.exporting = false;
                window.open(response.data.data.path);
              }, (err) => {
                self.exporting = false;
              });
            },
        },    
    }

</script>

<style scoped>
    h4 {
        text-align: left;
        padding-bottom: 1em;
    }

    .padding-bottom {
        padding-bottom: 5em;
    }

    @media print {
        .on-print {
            display: none;
        }
        .on-print-padding {
            padding-top: 5em;
        }
    }

    .card {
        border-color: #28a745;
        margin-bottom: 4em;
    }

    .chart{
        flex: 0 1 60%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #deebfd;
        box-shadow: 0 3px 10px rgba(62,85,120,.045);
        margin: 0 8px 30px;
    }

    div.ex1 {
        max-width: 600px;
        margin: auto;
        background: #ffffff;
        margin-bottom: 6em;
        border-radius: 6px;
        border: 1px solid #deebfd;
        box-shadow: 0 3px 10px rgba(62,85,120,.045);
    }

    div.ex2 {
        margin: auto;
        background: #ffffff;
        margin-bottom: 4em;
        border-radius: 6px;
        border: 1px solid #deebfd;
        box-shadow: 0 3px 10px rgba(62,85,120,.045);
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #28a745;
    }

    .a {
        color: #28a745;
        text-decoration: none;
        background-color: transparent;
    }


</style>

