var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"PedidosMultas"}},[_c('BodyTitle',{attrs:{"title":"Pedidos e Multas"}}),_c('FilterMultasPedidos',{staticClass:"on-print",attrs:{"event":"filter-change"},on:{"filter-change":function($event){return _vm.filterChange($event)}}}),(_vm.pedidosCollection.datasets.length > 0 || _vm.multasCollection.datasets.length > 0)?_c('div',{staticClass:"on-print-padding"},[_c('div',{staticClass:"mb-5"},[_c('ChartLine',{key:_vm.pedidosKey,attrs:{"datacollection":{
            labels: this.pedidosCollection.label,
            datasets: [
                {
                    label: ' Valor de Pedidos',
                    fill: false,
                    pointBackgroundColor: 'white',
                    borderWidth: 4,
                    borderColor: '#13d1f8',
                    pointBorderColor: '#13d1f8',
                    data: this.pedidosCollection.datasets
                },
            ]
            }}})],1),_c('div',{staticClass:"mb-5"},[_c('ChartLine',{key:_vm.multasKey,attrs:{"datacollection":{
            labels: this.multasCollection.label,
            datasets: [
                {
                    label: ' Valor de Multas',
                    fill: false,
                    pointBackgroundColor: 'white',
                    borderWidth: 4,
                    borderColor: '#e91e63',
                    pointBorderColor: '#e91e63',
                    data: this.multasCollection.datasets
                },
            ]
            }}})],1)]):_vm._e(),((_vm.multasCollection.datasets.length === 0 || _vm.pedidosCollection.datasets.length === 0) && !_vm.loading && _vm.filter !== null)?_c('p',{staticClass:"msg-length"},[_vm._v("Nenhuma informação encontrada.")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-spinner',{staticClass:"loading",attrs:{"variant":"success","label":"Loading..."}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }