<template>
    <div>
        <form @submit.stop.prevent="handleSubmit" ref="form">

            <b-form-group
                    label="Insira a senha atual:"
                    label-for="old_password"
            >
                <b-form-input
                        :state="old_passwordState"
                        type="password"
                        id="old_password"
                        required
                        v-model="old_password"
                        placeholder="Senha"
                />
            </b-form-group>

            <b-form-group
                    :state="passwordState"
                    label="Insira a nova senha:"
                    label-for="password"
            >
                <b-form-input
                        :state="passwordState"
                        type="password"
                        id="password"
                        required
                        v-model="password"
                        placeholder="Senha"
                />
            </b-form-group>

            <b-form-group
                    :state="password_confirmationState"
                    label="Insira novamente a senha:"
                    label-for="password_confirmation"
            >
                <b-form-input
                        :state="password_confirmationState"
                        type="password"
                        id="password_confirmation"
                        required
                        v-model="password_confirmation"
                        placeholder="Senha"
                />
                <b-form-invalid-feedback :state="password_confirmationState">
                    As senhas devem ser iguais.
                </b-form-invalid-feedback>
            </b-form-group>
        <p>{{ messageAlert }}</p>
            <b-btn style="float: right" type="submit" variant="success">Salvar</b-btn>
        </form>
    </div>
</template>
<script>
    import axios from "@/axios";
    import router from "@/router";

    export default {
        name: "AltterPassword",
        components: {},
        data: function () {
            return {
                old_password: '',
                old_password_return: true,
                password: '',
                password_confirmation: '',
                messageAlert: '',
            }
        },
        computed: {
            old_passwordState(){
                return this.old_password_return;
            },
            passwordState() {
                return this.password.length >= 2
            },
            password_confirmationState() {
                if (this.password_confirmation.length === 0 && this.password.length === 0) return true;
                return this.password_confirmation === this.password && this.password_confirmation.length >= 2
            },
        },
        methods: {
            handleSubmit() {
                let self = this;

                if (!self.passwordState || !self.password_confirmationState) {
                    self.messageAlert = 'Verifique se todos os campos foram preenchidos corretamente!';
                    return
                }
                let postSelected = {};

                postSelected.old_password = this.old_password;
                postSelected.password = this.password;
                postSelected.password_confirmation = this.password_confirmation;

                this.access_token = "Bearer " + localStorage.access_token;
                axios.put('/alter_password', postSelected, {
                    headers: {
                        "Authorization": self.access_token
                    }
                })
                    .then(function () {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user_permission');
                        localStorage.removeItem('user_name');
                        localStorage.removeItem('user_id');
                        self.login = "/login"
                        router.push("login");
                    })
                    .catch(function (error) {
                         // alert(error);
                        self.old_password_return = false;
                        console.log(error)
                    });
            }
        },
        mounted: function () {

        }
    };
</script>