<template>
    <div>
        <ButtonTable :status="clientLate(client.quantidadeItemsAtrasados)" :text="client.fornecedor"
                     v-b-toggle="'collapse-1' + index"/>
        <b-collapse :id="'collapse-1' + index" class="mt-2">
            <b-card class="card">
                <p class="text-center" v-if="client.pedidos.length === 0">Nenhum item encontrado.</p>
                <table v-else class="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Número</th>
                        <th scope="col">Itens</th>
                        <th scope="col">Valor total da multa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="request.pedido" v-for="(request,indexPedido) in client.pedidos">
                        <td scope="row">{{ request.pedido }}</td>
                        <td scope="row">
                            <b-button class="btn-collapse-inner" size="sm"
                                      @click="searchOrder(request.pedido, indexPedido)"
                                      v-b-toggle="'collapse-' + index + request.pedido + '-inner'">
                                    <span>
                                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="8" x2="21" y1="6" y2="6"></line>
                                            <line x1="8" x2="21" y1="12" y2="12"></line>
                                            <line x1="8" x2="21" y1="18" y2="18"></line>
                                            <line x1="3" x2="3" y1="6" y2="6"></line>
                                            <line x1="3" x2="3" y1="12" y2="12"></line>
                                            <line x1="3" x2="3" y1="18" y2="18"></line>
                                        </svg>
                                    </span>
                            </b-button>
                            <b-collapse :id="'collapse-' + index + request.pedido + '-inner'"
                                        class="mt-2">
                                <b-card v-if="pedido[indexPedido] !== undefined">
                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Texto</th>
                                            <th scope="col">Dia de Atraso</th>
                                            <th scope="col">Quantidade</th>
                                            <th scope="col">Valor do Item</th>
                                            <th scope="col">Valor da Multa</th>
                                            <th scope="col">Valor Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-bind:key="item.item" v-for="item in pedido[indexPedido]">
                                            <td scope="row">
                                                <div v-if="item.diasAtraso > 0">
                                                    <svg class="feather late-warning" viewBox="0 0 24 24"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="12" x2="12" y1="8" y2="12"></line>
                                                        <line x1="12" x2="12" y1="16" y2="16"></line>
                                                    </svg>
                                                </div>
                                            </td>
                                            <td scope="row">{{ item.item }}</td>
                                            <td scope="row">{{ item.texto }}</td>
                                            <td scope="row">{{ item.diasAtraso }}</td>
                                            <td scope="row">{{ item.quantidade }}</td>
                                            <td scope="row">{{ item.valorUnitario | money}}</td>
                                            <td scope="row">{{ item.valorMulta | money}}</td>
                                            <td scope="row">{{ ((item.quantidade * item.valorUnitario) + item.valorMulta) | money }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </b-card>
                            </b-collapse>
                        </td>
                        <td scope="row">{{ request.valorTotalMulta | money }}</td>
                    </tr>
                    </tbody>
                </table>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
    import axios from "@/axios";
    import ButtonTable from "@/components/ButtonTable";

    export default {
        name: "CollapseClient",
        components: {
            ButtonTable
        },
        props: {
            client: Object,
            index: Number
        },
        data() {
            return {
                cliente_status: 'success',
                count_late: 0,
                count_time: 0,
                access_token: '',
                pedido:[]
            }
        },
        filters:{
          money(value){
              let val = (value).toFixed(2).replace('.', ',');
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          }
        },
        methods: {
            searchOrder(pedido, index){
                if(pedido !== undefined && pedido !== null && this.pedido[index] === undefined){
                    this.access_token = "Bearer " + localStorage.access_token;
                    let self = this;
                    axios.get('/pedidos', {
                        params: {
                            fornecedor: self.client.fornecedor,
                            pedido: pedido,
                            this_week: true,
                        },
                        headers: {
                            "Authorization": self.access_token,
                        }
                    })
                        .then(function (response) {
                            self.pedido[index] = response.data.data;
                            self.$forceUpdate();
                        })
                        .catch(function (error) {
                            // eslint-disable-next-line no-console
                            console.log(error);
                        })
                }
            },
            clientLate: function (item) {
                if (item === 0) return "success";
                if (item <= 10) return "warning";
                if (item > 10) return "danger";

                return item;
            },
            requestLate: function (item) {
                let aux = false;
                // Object.keys(item).forEach(key => {
                //     if (item[key].diasAtraso !== 0) {
                //         aux = true;
                //     }
                // });
                return aux;
            }
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .card {
        margin-bottom: 1em;
    }

    .btn-collapse-inner {
        background: none;
        border-color: #6c757d;
        color: #6c757d;
    }

    .btn-collapse-inner:hover {
        background-color: #6c757d;
        color: #ffffff;
    }

    .late, .late-warning {
        background: none;
        border-color: #dc3545;
        color: #dc3545;
        stroke: #dc3545;
    }

    .late:hover {
        background-color: #dc3545;
        color: #ffffff;
        stroke: #ffffff;
    }

    .on_time {
        color: #28a745;
    }

</style>
