<template>
    <div id="IndexButton">
        <router-link :to="route">
            <b-button class="sidebar-button" v-on:click="clickEvent">
                <span>
                    <svg :style="styleSvg" class="feather" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <slot></slot>
                    </svg>
                </span>
                <span class="button-text">
                    {{ text }}
                </span>

            </b-button>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "IndexButton",
        props: {
            text: String,
            route: String,
            event: String,
            styleSvg: String
        },
        data: function () {
            return {}
        },
        methods: {
            clickEvent() {
                this.$emit(this.event)
            }
        }
    }
</script>

<style scoped>
#IndexButton:hover {
        cursor: pointer;
    }

    svg {
        width: 1.5em;
        height: 2.1em;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .sidebar-button {
        color: #999;
        border: solid 1px #ffffff;
        border-radius: 0;
        background: none;
        width: 100%;
        text-align: left;
        padding-left: 1em;
    }

    .sidebar-button:hover {
        color: #28a745;
        border: solid 1px #28a745;
        text-decoration: none;
    }

    .sidebar-button:focus {
        color: #ffffff;
        border: solid 1px #28a745;
        background-color: #28a745;
        box-shadow: none;
    }

    .sidebar-button:active {
        color: #ffffff;
        border: solid 1px #28a745 !important;
        background-color: #28a745 !important;
        box-shadow: none !important;
    }

    .button-text {
        text-align: left;
        margin-left: 1em;
        padding-top: 2em;
    }
</style>
