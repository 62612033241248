<template>
    <div id="BodyInput">
        <div class="input-group form-group">
            <div class="input-group-prepend">
                <span class="input-group-text">
                    <slot></slot>
                </span>
            </div>
            <b-form-input
                    :type="type"
                    v-model="value"
                    v-on:input="$emit(event, value)"
                    :placeholder="placeholder"
            ></b-form-input>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BodyInput",
        props: {
            event: String,
            type: String,
            placeholder: String,
        },
        data: function () {
            return {
                value: ''
            }
        }
    }
</script>

<style scoped>
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: white;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        margin-top: .35em;
    }

    input:focus {
        border-color: #28a745 !important;
        box-shadow: none !important;
    }

    .input-group-text {
        background-color: #28a745;
        border-color: #28a745;
        color: #ffffff;
        padding-top: 0;
    }

    .form-control {
        background: none;
        /*border-color: #6c757d;*/
    }
</style>
