import { render, staticRenderFns } from "./EnvioEmails.vue?vue&type=template&id=647b268c&scoped=true&"
import script from "./EnvioEmails.vue?vue&type=script&lang=js&"
export * from "./EnvioEmails.vue?vue&type=script&lang=js&"
import style0 from "./EnvioEmails.vue?vue&type=style&index=0&id=647b268c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647b268c",
  null
  
)

export default component.exports