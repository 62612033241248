<template>
    <div id="IndexUser">
        <div class="user-edit">
            <span class="user">
                <svg>
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <b class="user-name">{{ user_name }}</b>
            </span>
        </div>
<!--        <UsuarioEdit :id="userEdit" :item="userItem"></UsuarioEdit>-->
    </div>
</template>

<script>
    // import UsuarioEdit from "@/modal/UsuarioEdit";
    // import axios from 'axios';
    export default {
        name: "IndexUser",
        components: {
            // UsuarioEdit
        },
        props: {

        },
        data() {
            return {
                user_name: '',
                // userEdit: 'indexUserEdit',
                // userItem: {}
            }
        },
        methods: {
                // axios.get(
                //     '/user',
                //     {
                //         timeout: 5000,
                //         baseURL: process.env.VUE_APP_BASE_URL_LOGIN,
                //         headers: {
                //             "Authorization": access_token
                //         }
                //     })
                //     .then(function (response) {
                //         this.userItem = response.data.type;
                //
                //         console.log(this.userItem)
                //     })
                //     .catch(function (error) {
                //         console.log(error);
                // });

        },
        mounted() {
            this.user_name = localStorage.user_name;
        }
    }
</script>

<style scoped>
    #IndexUser {
    }
    svg {
        width: 1.5em;
        height: 1.5em;
        fill: none;
        stroke: #ffffff;
        stroke-width: .15em;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .user {
        float: left;
        color: #ffffff;
        vertical-align: baseline;
        margin: .6em;
    }
    .user-name {
        padding-left: .6em;
    }
    /*.user-edit {*/
    /*    cursor: pointer;*/
    /*}*/
</style>
