<template>
    <div>
        <b-modal
                :id="id"
                @hidden="resetModal"
                @ok="handleOk"
                @show="resetModal"
                cancelTitle='Cancelar'
                cancelVariant="outline-secondary"
                centered
                header-bg-variant="success"
                header-text-variant="light"
                okTitle="Salvar"
                okVariant="success"
                ref="modal"
                size="xl"
                title="Novo Usuários"
        >
            <form @submit.stop.prevent="handleSubmit" ref="form">
                <b-form-group
                        :state="nameState"
                        label="Insira o nome completo do novo usuário:"
                        label-for="name"
                >
                    <b-form-input
                            :state="nameState"
                            type="text"
                            id="name"
                            required
                            v-model="name"
                            placeholder="Nome e Sobrenome"
                    />
                </b-form-group>

                <b-form-group
                        :state="emailState"
                        label="Insira o email do novo usuário:"
                        label-for="email"
                >
                    <b-form-input
                            :state="emailState"
                            type="email"
                            id="email"
                            required
                            v-model="email"
                            placeholder="email@email.com"
                    />
                </b-form-group>

                <b-form-group
                        :state="typeState"
                        label="Selecione qual é o tipo do novo usuário:"
                        label-for="type"
                >
                    <b-form-select
                            :options="optionsType"
                            :state="typeState"
                            id="type"
                            required
                            v-model="type"
                    ></b-form-select>
                </b-form-group>

                <UserTag event="tag-change" v-on:tag-change="tagChange($event)" v-if="type === '2'">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" data-v-14b21170="" data-v-3264a8d8=""></path>
                    <circle cx="12" cy="7" data-v-14b21170="" data-v-3264a8d8="" r="4"></circle>
                </UserTag>

                <b-form-group
                        :state="usernameState"
                        label="Insira o nome de usuário que será utilizado para login:"
                        label-for="username"
                >
                    <b-form-input
                            :state="usernameState"
                            type="text"
                            id="username"
                            required
                            v-model="username"
                            placeholder="Usuário"
                    />
                </b-form-group>

                <b-form-group
                        :state="passwordState"
                        label="Insira a senha que será utilizado para login:"
                        label-for="password"
                >
                    <b-form-input
                            :state="passwordState"
                            type="password"
                            id="password"
                            required
                            v-model="password"
                            placeholder="Senha"
                    />
                </b-form-group>

                <b-form-group
                        :state="password_confirmationState"
                        label="Insira novamente a senha:"
                        label-for="password_confirmation"
                >
                    <b-form-input
                            :state="password_confirmationState"
                            type="password"
                            id="password_confirmation"
                            required
                            v-model="password_confirmation"
                            placeholder="Senha"
                    />
                    <b-form-invalid-feedback :state="password_confirmationState">
                        As senhas devem ser iguais.
                    </b-form-invalid-feedback>
                </b-form-group>
            </form>
            <p>{{ messageAlert }}</p>
        </b-modal>
    </div>
</template>

<script>
    import axios from "@/axios";
    import UserTag from "@/components/UserTag";

    export default {
        name: "UsuarioNovo",
        components: {
            UserTag
            // BodyTag
        },
        props: {
            id: String,
        },
        data() {
            return {
                name: '',
                username: '',
                email: '',
                password: '',
                password_confirmation: '',
                type: '0',
                users: [],
                messageAlert: '',

                access_token: '',

                optionsType: [
                    {text: 'Selecione uma opção', value: '0', disabled: true},
                    {text: 'Administrador', value: '1'},
                    {text: 'Supervisor', value: '2'},
                    {text: 'Comprador', value: '3'},
                    {text: 'Fornecedor', value: '4'}
                ],
            }
        },
        computed: {
            nameState() {
                return this.name.length >= 5
            },
            usernameState() {
                return this.username.length >= 2
            },
            isMail() {
                let is = false;
                for (let i = 0; i < this.email.length; i++) {
                    if (this.email.charAt(i) === '@' && i !== this.email.length - 1) {
                        return true;
                    }
                }
                return is;
            },
            emailState() {
                return this.email.length >= 5 && this.isMail
            },
            passwordState() {
                return this.password.length >= 2
            },
            password_confirmationState() {
                return this.password_confirmation === this.password && this.password_confirmation.length >= 2
            },
            typeState() {
                return this.type !== ''
            },
        },
        methods: {
            tagChange(event) {
                this.users = event;
            },
            resetModal() {
                this.name = '';
                this.username = '';
                this.email = '';
                this.password = '';
                this.password_confirmation = '';
                this.type = '';
                this.users = [];
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.handleSubmit()
            },
            handleSubmit() {
                let self = this;
                if (!self.nameState || !self.usernameState || !self.emailState || !self.passwordState || !self.password_confirmationState) {
                    self.messageAlert = 'Verifique se todos os campos foram preenchidos corretamente!';
                    return
                }
                let postSelected = {};
                postSelected.name = this.name;
                postSelected.username = this.username;
                postSelected.password = this.password;
                postSelected.password_confirmation = this.password_confirmation;
                postSelected.email = this.email;
                postSelected.type = this.type;
                postSelected.users = this.users;

                this.access_token = "Bearer " + localStorage.access_token;
                axios.post('/usuarios', postSelected, {
                    headers: {
                        "Authorization": self.access_token
                    }
                })
                    .then(function () {
                        self.$nextTick(() => {
                            self.$bvModal.hide(self.id);
                            self.$emit("refresh-table");
                        })
                    })
                    .catch(function (error) {
                        alert(error)
                    });
            }
        }
    }
</script>

<style scoped>
    input:focus {
        /*border-color: #28a745 !important;*/
        box-shadow: none !important;
    }
    p {
        font-size: 13px;
        color: #dc3545;
    }
</style>
